export const AcademyData = [
    {
        id: 1,
        title: 'Education Everywhere',
        content: 'Opportunities For Everyone',
        body: "At Astrobie Academy, we are dedicated to making high-quality educational resources accessible to individuals worldwide, breaking down barriers to learning and ensuring that everyone has the opportunity to succeed, no matter where they are.",
        image: "/images/1_Opportunities_For_Everyone.svg",
        color: '[#6650d5]'
    },{
        id: 2,
        title: 'Changing Lives',
        content: 'Unlocking Potential',
        body: "Our mission is to transform lives by helping individuals discover and unlock their full potential through education, providing them with the tools and support they need to achieve their personal and professional goals.",
        image: "/images/2_Unlocking_Potential.svg",
        color: '[#5ab489]'
    },{
        id: 3,
        title: 'Commitment With You',
        content: 'The Goal is Your Success',
        body: "We are committed to guiding and supporting each learner on their unique educational journey, ensuring that every step is taken with the goal of their success, and offering personalized resources and encouragement.",
        image: "/images/3_The_Goal_is_Your_Success.svg",
        color: '[#ffa664]'
    },{
        id: 4,
        title: 'Transforming Communities',
        content: 'Together for better',
        body: " We believe in the power of education to enhance and uplift entire communities. By working together and leveraging our global network, we aim to foster stronger, more resilient communities through shared learning and collective growth.",
        image: "/images/4_Together_for_Better.svg",
        color: '[#ff6464]'
    }
]

export const ModalsData=[
    {
        id:1,
        header:"Education Everywhere: Opportunities for everyone",
        body:"At Astrobie Academy, we believe in making education accessible to all. Our mission is to provide opportunities for everyone to learn and grow, regardless of their background or circumstances. By offering a wide range of educational resources, we aim to break down barriers and ensure that knowledge is within reach for everyone, everywhere.",
    },
    {
        id:2,
        header:"Changing Lives: Unlocking potential",
        body:"We are dedicated to changing lives by unlocking the potential within each individual. At Astrobie Academy, we empower learners to achieve their dreams and reach their full potential. Through our innovative and inclusive educational programs, we strive to inspire and motivate individuals to pursue their passions and make a positive impact on the world.",
    },
    {
        id:3,
        header:"Commitment with You: The goal is your success",
        body:"Our commitment is to you and your success. At Astrobie Academy, we focus on providing the support and resources you need to achieve your personal and professional goals. We are dedicated to your growth and development, ensuring that our mission aligns with your aspirations and helps you succeed in every endeavor.",
    },
    {
        id:4,
        header:"Transforming Communities: Together for better",
        body:"Together, we are transforming communities for the better. At Astrobie Academy, we believe in the power of collective effort and collaboration. By working hand in hand with our community, we aim to create a more educated and empowered world. Our mission is to foster a sense of unity and purpose, driving positive change and building a brighter future for all.",
    }
]

export const TestimonialsData=[
    {
        id: 1,
        name: 'Johan Olmos',
        title: "Founder and CEO of Astrobie LLC",
        text: " I understood that Astrobie Academy was something special that could change someone's life. I got inspired picturing myself and my team being the helping hand for talented individuals. It's all about helping others.",
        image: '/images/outsourcing_web_assistance.png',
        country:'United States',
        university:'Lewis University',
        color: 'bg-[#6650d5]',
    },
    {
        id: 2,
        name: 'Isaac Orozco',
        title: 'Co-Founder and CTO of Astrobie LLC',
        text: "Being part of Astrobie Academy is incredibly rewarding. I love the culture of innovation and collaboration within the team, which drives me to improve our offerings. My message to the world is that education is key to a better future, and we are committed to providing high-quality experiences that empower students. I am deeply grateful to our community for their support, as together we are building a brighter and more accessible future for all.",
        image: '/images/outsourcing_web_assistance.png',
        country:'Mexico',
        university:'ITSUR',
        color: 'bg-[#5ab489]',
    },
    {
        id: 3,
        name: 'Nancy Cervantes',
        title: 'Marketing Department Manager',
        text: "I have had the opportunity to meet people with an exceptional vision for improving the world through their knowledge, which has been inspiring. Additionally, I have been able to channel my passion into the work I do and share my experience with other collaborators, which has been extremely gratifying.",
        image: '/images/outsourcing_web_assistance.png',
        country:'Mexico',
        university:'Universidad Tres Culturas',
        color: 'bg-[#ff6464]',
    },
    {
        id: 4,
        name: 'Jose Perez',
        title: "Front-end Developer Intern",
        text: "Being part of Astrobie Academy means having the opportunity to positively impact the lives of many people through education, something I consider a great honor and responsibility. If I could give one message to the world, it would be that education is the key to opening doors and creating a better future.",
        image: '/images/outsourcing_web_assistance.png',
        country:'Mexico',
        university:'ITSUR',
        color: 'bg-[#ffa664]',
    },
    {
        id: 5,
        name: 'Kramer Mwangala',
        title: 'Astrobie Academy Ambassador in Africa',
        text: "What I appreciate most about Astrobie Academy is the inclusive environment where everyone is treated equally, regardless of their background. Being part of a team dedicated to addressing the high cost of certification for emerging tech professionals is incredibly meaningful, especially as it will greatly impact techies in Africa by providing them with certified skills.",
        image: '/images/outsourcing_web_assistance.png',
        country:'Kenya',
        university:'KCA University',
        color: 'bg-[#6650d5]',
    },
    {
        id: 6,
        name: 'Josue Morales',
        title: 'Software Engineer Intern',
        text: "Becoming a member of Astrobie Academy is more than just a role; it's an opportunity to support those who are in the same place I once was. I believe everyone has something to teach us, and we should share our knowledge to become better individuals and contribute to building a world with greater unity and understanding.",
        image: '/images/outsourcing_web_assistance.png',
        country:'Mexico',
        university:'ITSUR',
        color: 'bg-[#5ab489]',
    },
    {
        id: 7,
        name: 'Saul Zavala',
        title: 'Software Developer Intern',
        text: "Being part of Astrobie Academy is an incredibly enriching experience. Knowing that my work will help improve the lives of many people around the world fills me with happiness and pride. From day one, working with this team has been a very rewarding experience. I felt very welcomed, and the ease with which both doubts and ideas can be expressed is something I truly value.",
        image: '/images/outsourcing_web_assistance.png',
        country:'Mexico',
        university:'ITSUR',
        color: 'bg-[#ff6464]',
    },
]