import React, {useEffect, useState} from 'react'
import VolunteerForm from './VolunteerForm'

function Volunteer() {

    return(
        <VolunteerForm/>
    )
}

export default Volunteer