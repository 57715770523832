import React from "react";
import { useForm } from "react-hook-form";
import { Input, Select, SelectItem, Button } from "@nextui-org/react";
import { motion } from "framer-motion";
import { TabTitle } from "./dynamicTitles";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase/config";
import Toast from "./Alert";

function NotAvailable() {
  
  TabTitle('Available Soon');
  
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    setValue,
    trigger,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      gender: "NA", // Default value for gender
      country: "OT", // Default value for country
      email: "",
      confirmEmail: "",
    },
  });

  const onSubmit = (data) => {
    console.log(data);

    const questionRef = collection(db, "AvailableSoon");
    addDoc(questionRef, data)
      .then((doc) => {
        reset();
        Toast.fire({
          icon: "success",
          title: "Good question! Someone will review and answer your question",
        });
      })
      .catch((reason) => {
        Toast.fire({
          icon: "error",
          title: "We have had some problems, please try again.",
        });
      });
  };

  const email = watch("email");
  const confirmEmail = watch("confirmEmail");

  return (
    <section className="px-6 m-auto text-[#1D1D1D] w-full flex items-center justify-center">
      <div className="w-full max-w-[1144px] mt-20 mb-24 md:mt-24 md:mb-24 grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="order-last flex items-center justify-center w-full md:h-96 h-40">
          {/* <img
                        className="object-scale-down w-full h-full mx-auto rounded-md"
                        src="/images/astrobie_icon.png"
                        alt=""
                    /> */}
          <motion.img
            className="object-contain w-full max-w-xs h-full max-h-64 mx-auto rounded-md"
            src="/images/astrobie_icon.png"
            alt=""
            animate={{
              y: [0, -20, 0],
            }}
            transition={{
              duration: 2,
              ease: "linear",
              repeat: Infinity,
              repeatType: "loop",
            }}
          />
        </div>

        <div className="flex items-center justify-center">
          <div className="max-w-2xl mx-auto">
            <div className="text-4xl font-semibold">
              <span className="text-[#1D1D1D]">Coders at work!</span>
            </div>
            <p className="text-xl text-[#1D1D1D] mb-8">Coming soon</p>
            <p className="my-6 text-justify text-[#1d1d1d]">
              Very soon we will be launching our incredible range of products,
              and we don't want you to miss out. Fill out the form below and be
              the first to receive the latest news and information about our
              launch, sign up now and stay up to date with what's to come!
            </p>

            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <div className="grid xl:grid-cols-2 xl:gap-6">
                <div className="relative z-0 mb-6 w-full group">
                  <Input
                    {...register("firstName", {
                      required: "First name is required",
                      pattern: {
                        value: /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s'-]{1,50}$/,
                        message: "Invalid first name",
                      },
                    })}
                    isInvalid={!!errors.firstName}
                    color={errors.firstName ? "danger" : ""}
                    errorMessage={errors.firstName?.message}
                    type="text"
                    label="First Name"
                    className="max-w-xs"
                    isRequired
                  />
                </div>
                <div className="relative z-0 mb-6 w-full group">
                  <Input
                    {...register("lastName", {
                      required: "Last name is required",
                      pattern: {
                        value: /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s'-]{1,50}$/,
                        message: "Invalid last name",
                      },
                    })}
                    isInvalid={!!errors.lastName}
                    color={errors.lastName ? "danger" : ""}
                    errorMessage={errors.lastName?.message}
                    type="text"
                    label="Last name"
                    isRequired
                  />
                </div>
              </div>

              <div className="grid xl:grid-cols-2 xl:gap-6">
                <div className="relative z-0 mb-6 w-full group">
                  <Select
                    {...register("gender")}
                    label="Choose a gender"
                    isInvalid={!!errors.gender}
                    errorMessage={errors.gender?.message}
                    isRequired
                  >
                    <SelectItem key={"M"}>Male</SelectItem>
                    <SelectItem key={"F"}>Female</SelectItem>
                    <SelectItem key={"NB"}>Non-binary</SelectItem>
                    <SelectItem key={"NA"}>
                      I prefer not to specify
                    </SelectItem>
                  </Select> 
                </div>
                <div className="relative z-0 mb-6 w-full group">
                  <Select
                    {...register("country")}
                    label="Choose a country"
                    isInvalid={!!errors.country}
                    errorMessage={errors.country?.message}
                    isRequired
                  >
                    <SelectItem key={"US"}>United States</SelectItem>
                  </Select>
                </div>
              </div>

              <div className="relative z-0 mb-6 w-full group">
                <Input
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid email address",
                    },
                  })}
                  isInvalid={!!errors.email}
                  color={errors.email ? "danger" : ""}
                  errorMessage={errors.email?.message}
                  type="email"
                  label="Email address"
                  isRequired
                />
              </div>

              <div className="relative z-0 mb-6 w-full group">
                <Input
                  {...register("confirmEmail", {
                    required: "Confirm email is required",
                    validate: (value) =>
                      value === email || "Emails do not match",
                  })}
                  isInvalid={!!errors.confirmEmail}
                  color={errors.confirmEmail ? "danger" : ""}
                  errorMessage={errors.confirmEmail?.message}
                  type="email"
                  label="Confirm email address"
                  isRequired
                />
              </div>
              <p className="text-xs mb-6 text-[#1d1d1d]">
                We will never share or sell your information with anyone else
              </p>
              <Button
                type="submit"
                className="text-[#FDFFFC] bg-[#1D64C1] rounded-full"
              >
                Send
              </Button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NotAvailable;
