import React, {useState} from 'react'
import {Button, Input} from "@nextui-org/react";
import { collection, addDoc } from "firebase/firestore";
import { TabTitle } from '../dynamicTitles';
//import { db } from '../firebase/config.js';


function ComingSoon() {

    TabTitle('Astrobie Academy');

    const [isLoading, setIsLoading] = useState(false);
        

    return (
        <section className='bg-[#FDFFFC] px-6 m-auto text-[#1D1D1D] w-[100%] flex items-center justify-center'>
            <div className='max-w-[1144px] mt-20 mb-24 md:mt-24 md:mb-24'>
                <div className='grid grid-cols-1 text-center px-16 py-16'>
                            <p className='text-lg text-[#1d64c1bb] font-semibold uppercase mb-5'>Coming Soon</p>
                            <p className='font-semibold text-5xl'>Astrobie Academy</p>
                            <p className='font-semibold text-4xl'>is on the way!</p>
                            <p className='mt-5'>Get notifications when it's ready</p>
                            <Input
                                type="email"
                                label="Your email address"
                                radius='full'
                                className='mt-8'
                                endContent={
                                    <Button className="text-[#FDFFFC] bg-[#1D64C1] rounded-full">Send</Button>
                                }
                                />
                            <p className='text-xs mt-2'>We'll never share your email with anyone else</p>
                        </div>
            </div>
        </section>
    )
}

export default ComingSoon