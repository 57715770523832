import React, {useEffect, useState} from 'react'
import EnterpraiseForm from './EnterpriseForm'

function Enterprise() {

    return(
        <EnterpraiseForm/>
    )
}

export default Enterprise