import React, { useEffect } from "react";
import { Parallax } from "react-scroll-parallax";
import { animate, motion, useMotionValue } from "framer-motion";
import { useMeasure } from "@uidotdev/usehooks";

const SquareDesign = 
"h-32 w-32 m-5 max-md:text-tiny text-white rounded-xl shadow-xl flex items-end justify-start bg-[#1D1D1D]";
const cardsType = [
  {
    name: "Darkness",
    type: "plastic",
    image:
      "https://img.freepik.com/foto-gratis/textura-oscura-fondo_23-2147661187.jpg?t=st=1723686302~exp=1723689902~hmac=a94285458bd561b62ef0868eec6abf7f49ce0786082cb02500ad45aed49239e0&w=740",
  },
  {
    name: "White",
    type: "plastic",
    image:
      "https://img.freepik.com/foto-gratis/textura-papel-blanco_1194-5705.jpg?t=st=1723686408~exp=1723690008~hmac=615bd079e40bf9f3570d76cc3f3d67d23110ce67546c75167d9a3c5adc1f7f91&w=740",
  },
  {
    name: "Fiber carbon",
    type: "plastic",
    image:
      "https://img.freepik.com/foto-gratis/papel-pintado-rayas-negro_1194-6565.jpg?t=st=1723686525~exp=1723690125~hmac=4373e8b470e15f26aed8305d8ebe5339735f55fcbe2974f3b8d33bd5b0857c23&w=740",
  },
  {
    name: "Navy",
    type: "plastic",
    image:
      "https://img.freepik.com/foto-gratis/fondo-texturizado-pared-yeso-solido_53876-129487.jpg?t=st=1723686704~exp=1723690304~hmac=92240706212fcf73003002078de0d508ff052a49131ae8d3a55ad37f9d62e115&w=740",
  },
  {
    name: "Golden",
    type: "steel",
    image:
      "https://img.freepik.com/foto-gratis/fondo-texturizado_53876-41751.jpg?t=st=1723686818~exp=1723690418~hmac=2c588d13aa9ed873e8a4031ac2291389f1889a26f480afac08e3533e040b607b&w=740",
  },
  {
    name: "Silver",
    type: "steel",
    image:
      "https://img.freepik.com/fotos-premium/fondo-textura-metalica-diseno-industrial_707519-27748.jpg?w=826",
  },
  {
    name: "Smoked",
    type: "steel",
    image:
      "https://img.freepik.com/foto-gratis/textura-metalica-sucia_1048-4784.jpg?t=st=1723686924~exp=1723690524~hmac=554c23bc1b1932ae11baf75fd90afc72b15fa704ee0141a3a80b4ee7b40c8b85&w=740",
  },
  {
    name: "Oxide",
    type: "steel",
    image:
      "https://img.freepik.com/foto-gratis/vista-superior-superficie-metal-oxidado_23-2148796565.jpg?t=st=1723687086~exp=1723690686~hmac=bfe01a164144aba18ab7c257cb3ca9943fd41b400ff9cfff89f5a371f40d087f&w=740",
  },
  {
    name: "Dark Wood",
    type: "earth",
    image:
      "https://img.freepik.com/foto-gratis/diseno-fondo-textura-suelo-madera_53876-108357.jpg?t=st=1723687130~exp=1723690730~hmac=68f5c916fcfd06def05f3368efbab17258484327409e6483e49924c281a8fd51&w=740",
  },
  {
    name: "Light Wood",
    type: "earth",
    image:
      "https://img.freepik.com/foto-gratis/fondo-suelo-textura-madera-beige_53876-129605.jpg?t=st=1723687166~exp=1723690766~hmac=b85865dba1ac255e85b8188bba6e5c21f0f2581f41e49d0d25835485e2c1018f&w=740",
  },
  {
    name: "Recycled",
    type: "earth",
    image:
      "https://img.freepik.com/foto-gratis/textura-papel-vista-superior_23-2149061966.jpg?t=st=1723687271~exp=1723690871~hmac=a7813177d2f677c464a8c7f83a13a9fe0d210b2ae70b07d800300614d51042bc&w=740",
  },
  {
    name: "Glass",
    type: "earth",
    image:
      "https://img.freepik.com/foto-gratis/fotografia-patron-textura-espuma-poliestireno_58702-12012.jpg?t=st=1723687341~exp=1723690941~hmac=0b6321e28e21e37b26d7fb098c977b3ded9cf4f223630c587a5b370614d7f987&w=740",
  },
  {
    name: "Rubber",
    type: "plastic",
    image:
      "https://img.freepik.com/foto-gratis/fondo-textura-tela-pana-oscura_53876-101965.jpg?t=st=1723687468~exp=1723691068~hmac=adee97fb93bdc06181ebc52f820dd619d5137a07f233ff3be107da219b033763&w=740",
  },
  {
    name: "concrete",
    type: "plastic",
    image:
      "https://img.freepik.com/foto-gratis/muro-hormigon-gris_53876-90728.jpg?t=st=1723687552~exp=1723691152~hmac=06fe574933bef195f1da281c60e1c09708a4a6f21fcbeb4926bc75d646f35e7e&w=740",
  },
  {
    name: "Esmeralda",
    type: "steel",
    image:
      "https://img.freepik.com/foto-gratis/papel-tapiz-fondo-artistico-efecto-semitono-color_58702-9036.jpg?t=st=1723748840~exp=1723752440~hmac=a4af92dd58552c061ccc9475dfc2def3b134cb91f6b6d298dffbe58e0555991e&w=740",
  },
  {
    name: "Formal",
    type: "steel",
    image:
      "https://img.freepik.com/foto-gratis/primer-plano-marmol-textura-fondo_53876-64734.jpg?t=st=1723749085~exp=1723752685~hmac=fabd270b0addcb9b09ec0968c88da0b1791ffbe086f54c8a291077f28a7f2240&w=740",
  },
];

export function Square({ name = "name", background = "#020303", className }) {
  // Determina si el background es una URL de imagen (comienza con '#')
  const isColor = typeof background === "string" && background.startsWith("#");

  // Define el estilo en línea basado en el tipo de background
  const style = isColor
    ? { background }
    : {
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      };

  return (
    <div
      className={`md:h-28 2xl:h-32 2xl:w-32 md:w-28 h-16 w-16 m-5 p-2 max-md:text-tiny text-white rounded-xl shadow-2xl flex items-end justify-start ${className}`}
      style={style}
    >
      {name}
    </div>
  );
}

export function TopChooseSquares() {
  let [ref, { width }] = useMeasure();

  const xTranslation = useMotionValue(0);

  useEffect(() => {
    let controls;
    let finalPosition = -width / 2 - 8;

    controls = animate(xTranslation, [0, finalPosition], {
      ease: "linear",
      duration: 50,
      repeat: Infinity,
      repeatType: "loop",
      repeatDelay: 0,
    });

    return controls.stop;
  }, [xTranslation, width]);

  return (
    <main>
      {/* top carousel */}
      <motion.div
        className="absolute left-0 top-0 max-md:top-24 flex gap-4"
        style={{ x: xTranslation }}
        ref={ref}
      >
        {cardsType
          .filter((_, index) => index < cardsType.length / 2 + 1)
          .map((value, index) => (
            <Square key={index} name={value.name} background={value.image} />
          ))}

        {/* these divs are cloned to create the infinity effect */}
        {cardsType
          .filter((_, index) => index < cardsType.length / 2 + 1)
          .map((value, index) => (
            <Square key={index} name={value.name} background={value.image} />
          ))}
      </motion.div>
    </main>
  );
}

export function BottomChooseSquares() {
  let [ref, { width }] = useMeasure();

  const xTranslation = useMotionValue(0);

  useEffect(() => {
    let controls;
    let finalPosition = -width / 2 - 8;

    controls = animate(xTranslation, [finalPosition, 0], {
      ease: "linear",
      duration: 50,
      repeat: Infinity,
      repeatType: "loop",
      repeatDelay: 0,
    });

    return controls.stop;
  }, [xTranslation, width]);

  return (

    <main>
      {/* bottom carousel */}
      <motion.div
        className="absolute left-0 bottom-0 flex gap-4"
        style={{ x: xTranslation }}
        ref={ref}
      >
        {cardsType
          .filter((_, index) => index >= cardsType.length / 2 + 1)
          .map((value, index) => (
            <Square key={index} name={value.name} background={value.image} />
          ))}
        {/* these divs are cloned to create the infinity effect */}
        {cardsType
          .filter((_, index) => index >= cardsType.length / 2 + 1)
          .map((value, index) => (
            <Square key={index} name={value.name} background={value.image} />
          ))}
      </motion.div>
    </main>
  );
}

export function PlasticSquares({ material }) {
  const filterCard = cardsType.filter((value, _) => value.type === material);

  const middleIndex = Math.ceil(filterCard.length / 2);
  const firstHalf = filterCard.slice(0, middleIndex);
  const secondHalf = filterCard.slice(middleIndex);

  return (
    <div className="h-[100vh]">
      <Parallax className="grid grid-cols-3 gap-4 h-full items-center justify-items-center">
        <div className="col-span-1 space-y-14">
          {firstHalf.map((value, index) => (
            <Parallax
              key={index}
              scale={[0, 1, "easeInOutQuad"]}
              opacity={[0, 1, "easeInOutCirc"]}
              translateY={[-50, 0, "easeInOutQuad"]}
              speed={5}
            >
              <Square key={index} name={value.name} background={value.image} />
            </Parallax>
          ))}
        </div>
        <div className="col-span-1" />
        <div className="col-span-1 space-y-14">
          {secondHalf.map((value, index) => (
            <Parallax
              key={index}
              scale={[0, 1, "easeInOutQuad"]}
              opacity={[0, 1, "easeInOutCirc"]}
              translateY={[100, 0, "easeInOutQuad"]}
              speed={5}
            >
              <Square key={index} name={value.name} background={value.image} />
            </Parallax>
          ))}
        </div>
      </Parallax>
    </div>
  );
}

export function SteelSquares() {
  return (
    <Parallax
      opacity={[2, 0, "easeInOutCubic"]}
      className="relative w-full max-w-7xl h-full align-middle"
    >
      {/* **Square 1 ** */}
      <motion.div
        initial={{ opacity: 0, x: -150, scale: 0 }}
        whileInView={{ opacity: 100, x: 0, scale: 1 }}
        transition={{ duration: 3 }}
        className={`${SquareDesign} absolute top-[5%] left-[55%] 2xl:left-1/3 2xl:top-[15%] `}
      >
        name1
      </motion.div>

      {/* **Square 2 ** */}
      <motion.div
        initial={{ opacity: 0, x: -150, scale: 0 }}
        whileInView={{ opacity: 100, x: 0, scale: 1 }}
        transition={{ duration: 3 }}
        className={`${SquareDesign} absolute top-[30%] left-[25%] 2xl:left-0 2xl:top-[35%]`}
      >
        name2
      </motion.div>

      {/* **Square 3 ** */}
      <motion.div
        initial={{ opacity: 0, x: -150, scale: 0 }}
        whileInView={{ opacity: 100, x: 0, scale: 1 }}
        transition={{ duration: 3.5 }}
        className={`${SquareDesign} absolute bottom-[10%] left-[80%] 2xl:left-2/3 2xl:bottom-[20%]`}
      >
        name3
      </motion.div>

      {/* **Square 4 ** */}
      <motion.div
        initial={{ opacity: 0, x: -150, scale: 0 }}
        whileInView={{ opacity: 100, x: 0, scale: 1 }}
        transition={{ duration: 2.5 }}
        className={`${SquareDesign} absolute bottom-[25%] left-[45%] 2xl:left-[20%] 2xl:bottom-[35%]`}
      >
        name4
      </motion.div>
    </Parallax>
  );
}

export function EarthSquares() {
  return (
    <Parallax
      opacity={[2, 0, "easeInOutCubic"]}
      className="relative w-full max-w-7xl h-full align-middle"
    >
      <motion.div
        initial={{ opacity: 0, y: 100, scale: 0 }}
        whileInView={{ opacity: 100, y: 0, scale: 1 }}
        transition={{ duration: 2.5 }}
        className={`${SquareDesign} absolute top-[25%] right-[80%] `}
      >
        name1
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 100, scale: 0 }}
        whileInView={{ opacity: 100, y: 0, scale: 1 }}
        transition={{ duration: 2.5 }}
        className={`${SquareDesign} absolute top-[55%] right-[65%] `}
      >
        name2
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 100, scale: 0 }}
        whileInView={{ opacity: 100, y: 0, scale: 1 }}
        transition={{ duration: 2.5 }}
        className={`${SquareDesign} absolute top-[35%] left-[45%] `}
      >
        name3
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 100, scale: 0 }}
        whileInView={{ opacity: 100, y: 0, scale: 1 }}
        transition={{ duration: 2.5 }}
        className={`${SquareDesign} absolute top-[65%] left-[60%] `}
      >
        name4
      </motion.div>
    </Parallax>
  );
}
