import React from 'react'

import { SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {Card, CardHeader, CardBody, CardFooter, Avatar, Button} from "@nextui-org/react";
import { College, Location } from '../resources/Icons';


const TestimonialsCard = (props) => {
    return (
        <SwiperSlide className='py-8 mb-8'>
            <Card className={`w-fit md:max-w-[456px] xl:max-w-[500px] h-[270px] p-3 text-[#FDFFFC] ${props.color}`}>
                <p className='absolute right-4 text-8xl'>“</p>
                <CardHeader className="flex-col items-start">
                    <p className="text-xl font-semibold">{props.name}</p>
                    <p className="text-sm">{props.title}</p>
                </CardHeader>
                <CardBody className="text-xs">
                    <p>
                        {props.text}
                    </p>
                </CardBody>
                <CardFooter className='flex-row items-center justify-start'>
                    <img src='/images/school.svg' className='h-5 w-5' alt='College'/>
                    <p className='text-xs ml-1 mr-3'>{props.university}</p>
                    <img src='/images/location.svg' className='h-5 w-5' alt='Location'/>
                    <p className='text-xs ml-1 mr-3'>{props.country}</p>
                </CardFooter>
            </Card>
        </SwiperSlide>
    );
};

export default TestimonialsCard;