import React from "react";
import { Card, CardBody, Divider } from "@nextui-org/react";
const MeetUsAt = () => {
  const categories = [
    {
      name: "Description",
      nameMovil: "Description",
      title: "What Is the Astrobie Academy Project?",
      sentence: "Empowering future innovators through cutting-edge education.",
      content: "Astrobie Academy, an initiative by Astrobie LLC, is dedicated to breaking barriers between individuals and success by providing free online educational resources. As a non-profit organization, Astrobie Academy is committed to leading the way in supporting people in need around the world. Our mission is made possible through the invaluable contributions of our partners and volunteers, who share our vision of empowering communities and fostering global education.",
      colorSentence: "text-[#5AB489]",
    },
    {
      name: "Community",
      nameMovil: "Community",
      title: "The Power of Community",
      sentence: "Together, we can achieve more, fostering connection, collaboration, and shared growth.",
      content: "Our community at Astrobie Academy is dedicated to serving others. We firmly believe in the transformative power of education in people's lives. Our success is deeply rooted in the unwavering support, appreciation, and resources provided by our committed community of talented individuals. Community is the cornerstone of our project. It fosters collaboration, innovation, and mutual growth. Our diverse community includes members from Mexico, the United States, Kenya, India, and Colombia, enriching our perspectives and approaches. Additionally, our partnerships with organizations, colleges, and universities worldwide enhance our ability to provide high-quality educational resources and support.",
      colorSentence:"text-[#6650D5]",
    },
    {
      name: "Mission",
      nameMovil: "Mission",
      title: "Mission Statement",
      sentence: "Innovate, collaborate, and empower positive change",
      content: "Astrobie Academy, an initiative by Astrobie LLC, is dedicated to breaking barriers to success by providing free, high-quality online educational resources. Our mission is to empower individuals worldwide through education, fostering a diverse and inclusive community. We strive to lead the way in global education. Together, we aim to transform lives and create opportunities for all.",
      colorSentence:"text-[#FFA664]",
    },
    {
      name: "Benefit",
      nameMovil: "Benefit",
      title: "Benefits for Our People",
      sentence: "Empowering our people with resources, opportunities, and support to succeed.",
      content: "Astrobie Academy provides numerous benefits to people, including access to free, high-quality educational resources, which break financial barriers to learning. By empowering individuals with knowledge, we help them improve their lives and communities. Our diverse and inclusive global community connects learners with peers and mentors worldwide, offering support and guidance through partnerships with organizations, colleges, and universities. This education opens doors to new opportunities in further education, employment, and personal development, collectively aiming to transform lives and create opportunities for all, fostering a brighter future for individuals and communities worldwide.",
      colorSentence:"text-[#FF6464]",
    },
  ];
  const [activeCategory, setActiveCategory] = React.useState(categories[0]);
  return (
      <section className="px-6 m-auto text-[#1D1D1D] w-[100%] flex items-center justify-center">
        <div className="max-w-[1144px] mt-20 mb-24 md:mt-24 md:mb-24"> {/* Removed grid grid-rows-2 */}
          <div className="text-4xl font-semibold px-4">
            <p className="text-[#1D1D1D]">Get to know our project</p>
          </div>
          {/* Desktop */}
          <div className="flex w-full flex-col max-w-[1144px] mt-10 mb-24 hidden md:flex">
            <div className="flex p-4 space-x-8">
              <div className="flex flex-col space-y-4">
                {categories.map((category, index) => (
                    <div>
                      <button
                          key={category.name}
                          className={`text-center text-lg pl-2 rounded-md w-56 h-14 ${activeCategory.name === category.name ? "text-[#1D1D1D] font-semibold" : "text-[#1D1D1Dc1]"}`}
                          onClick={() => setActiveCategory(category)}
                      >
                        {category.name}
                      </button>
                      {index < categories.length - 1 && (
                          <Divider className="mt-4" />
                      )}
                    </div>
                ))}
              </div>
              <Card className="bg-[#F4F6F3] h-[400px] ">{/* <- Add max-heigh */}
                <CardBody>
                  <div>
                    <h1 className={`text-xs italic px-4 pt-4 ${activeCategory.colorSentence}`}>{activeCategory.sentence}</h1>
                    <h1 className="text-2xl font-semibold px-4 pt-4">{activeCategory.title}</h1>
                    <p className="py-4 px-4 text-sm">{activeCategory.content}</p>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
          {/* Mobile Version */}
          <div className="flex w-full flex-col max-w-[1144px] mt-20 mb-24 md:mt-24 md:mb-24 md:hidden">
            <div className="flex pb-4 ">
              <div className="flex flex-row">
                {categories.map((category, index) => (
                    <div className="flex items-center justify-center">
                      <button
                          key={category.nameMovil}
                          className={`flex items-center justify-center text-xs rounded-md w-20 h-20 ${activeCategory.nameMovil === category.nameMovil ? "text-[#1D1D1D] font-semibold" : "text-[#1D1D1Dc1]"}`}
                          onClick={() => setActiveCategory(category)}
                      >
                        {category.nameMovil}
                      </button>
                      {index < categories.length - 1 && (
                          <Divider orientation="vertical" className="mx-2" />
                      )}
                    </div>
                ))}
              </div>
            </div>
            <Card className="bg-[#F4F6F3] w-full">
              <CardBody>
                <h1 className={`text-xs italic px-4 pt-4 ${activeCategory.colorSentence}`}>{activeCategory.sentence}</h1>
                <h1 className="text-2xl font-semibold px-4 pt-4">{activeCategory.title}</h1>
                <p className="py-4 px-4">{activeCategory.content}</p>
              </CardBody>
            </Card>
          </div>
        </div>
      </section>
  );
};
export default MeetUsAt;