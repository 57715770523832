import React from 'react'
import {NewsData} from "./data";
import NewsCards from "./NewsCards";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import {Pagination, FreeMode} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

function News() {
    return (
        <section className='bg-[#F4F6F3] text-[#1D1D1D] py-2 w-[100%] flex-col items-center justify-center'>
            <div className='mt-20 mb-24 md:mt-24 md:mb-24 px-6 mx-auto flex-column content-center static gap-8 max-w-[1144px]'>

                <div className="text-4xl md:text-4xl font-semibold">
                    <span className="text-[#1D1D1D]">
                        Announces
                    </span>
                </div>

                <p className='text-xl text-[#1D1D1D] mb-8'>See what's happening at Astrobie</p>

                <div>
                <Swiper
                        style={{
                            "--swiper-pagination-color": "#1D65C1",
                            "--swiper-pagination-bullet-inactive-color": "#989997",
                            "--swiper-pagination-bullet-inactive-opacity": "1",
                            "--swiper-pagination-bullet-size": "8px",
                            "--swiper-pagination-bullet-horizontal-gap": "6px",
                        }}
                        modules={[FreeMode, Pagination]}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                                spaceBetween: 20
                            },
                            400: {
                                slidesPerView: 1.3,
                                spaceBetween: 40,
                            },
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            735: {
                                slidesPerView: 2.5,
                                spaceBetween: 60,
                            },
                            1024: {
                                slidesPerView: 3.3,
                                spaceBetween: 60,
                            }
                        }}

                        freeMode={true}
                        pagination={{
                            clickable: true
                        }}
                        className='max-w-[1144px]'
                    >
                        {NewsData.map((item) => (
                            <SwiperSlide key={item.id}>
                                <NewsCards id={item.title} title={item.title} subtitle={item.subtitle} image={item.image} heading={item.heading} body={item.body}/>
                            </SwiperSlide>
                        ))}

                    </Swiper>
                </div>
            </div>
        </section>
    )
}

export default News