import React, { useEffect, useState } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import MaterialSection from "./MaterialSection";
import {
  BottomChooseSquares,
  TopChooseSquares,
} from "./SquaresAnimations";

const ScrollParallax = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section
      name="materials"
      className="bg-[#FDFFFC] px-6 m-auto text-[#1D1D1D] w-[100%] flex items-center justify-center"
    >
      <div className="max-w-[1144px] mt-20 mb-24 md:mt-24 md:mb-24  items-center justify-center">
        <ParallaxProvider>
          <div className="relative w-full max-w-7xl h-[80vh] flex justify-center items-center overflow-hidden">
            {/* center container */}
            <div className="absolute flex flex-col items-center text-center justify-center z-30">
              <p className="text-4xl font-semibold">Get To Choose Your</p>
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#1D64C1] to-[#CD8987] text-[5rem] font-semibold">Material</span>
              {/* <p className="text-xl text-[#1D64C1] mb-3 font-semibold">Make sure you love it</p> */}
            </div>
            <TopChooseSquares />
            <BottomChooseSquares />
          </div>

         
            {/* Plastic */}
            <MaterialSection
              title={"Plastic"}
              subtitle={"Make sure you love it"}
              description={
                "Decide which material fits better your needs. You can also opt to Decide which material fits better your needs. You can also opt to Decide which material fits better your needs. You can also opt to Sustainable materials available."
              }
              material={"plastic"}
            />
         
            {/* Stainless Steel */}
            <MaterialSection
              title={"Stainless Steel"}
              subtitle={"Make sure you love it"}
              description={
                "Decide which material fits better your needs. You can also opt to Decide which material fits better your needs. You can also opt to Decide which material fits better your needs. You can also opt to Sustainable materials available."
              }
              material={"steel"}
              right={true}
            />
         
            {/* Earth Care */}
            <MaterialSection
              title={"Earth Care"}
              subtitle={"Make sure you love it"}
              description={
                "Decide which material fits better your needs. You can also opt to Decide which material fits better your needs. You can also opt to Decide which material fits better your needs. You can also opt to Sustainable materials available."
              }
              material={"earth"}
            />


          <div className="sticky top-0 h-[90vh] z-10" />

          
        </ParallaxProvider>
      </div>
    </section>
  );
};

export default ScrollParallax;
