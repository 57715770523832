export const ConnectivityData = [
    {
        id: 1,
        title: 'Easy To Operate',
        content: 'A Natural Extencion Of Your Phone',
        image: '',
        color: '[#EE964B]'
    },{
        id: 2,
        title: 'Ready To Go',
        content: 'No App. No Setting Up.',
        image: '',
        color: '[#84828F]'
    },{
        id: 3,
        title: 'Technology On Your Side',
        content: 'High Technology',
        image: '',
        color: '[#CD8987]'
    },{
        id: 4,
        title: 'Only Share Wath You Want',
        content: 'Your Information Remains Private',
        image: '',
        color: '[#94BB9B]'
    },{
        id: 5,
        title: 'Easy To Operate',
        content: 'A Natural Extencion Of Your Phone',
        image: '',
    }
]