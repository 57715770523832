import React from 'react'

export default function Information() {
    return (
        <section name="information" className='p-0 m-0 py-32 bg-[#F4F6F3] text-[#1D1D1D]  flex-col items-center justify-center '>
            <div className=' px-6 text-[#1D1D1D] mx-auto flex-column content-start static gap-8 max-w-[1144px] '>
                <div >
                    <span className="text-4xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-[#1D1D1D] to-[#1D1D1D]">
                        Astrobie Card
                    </span>
                    <p className='text-xl text-[#1D1D1D] mb-8'>The smart card to connect the world.</p>
                </div>
                <div className='grid'>
                    <p className='text-lg'>Astrobie Card: An intelligent card designed to streamline how you share your personal or professional information. With a minimalist approach and sustainable materials, this card is more than just a presentation<strong>—it’s your gateway to a world of possibilities.</strong></p>
                    <div className='grid grid-cols-1 md:grid-cols-3 my-20 gap-10'>
                        <div> 
                        <svg className='h-24 w-24 fill-[#1D1D1D]' xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M760-120q-39 0-70-22.5T647-200H440q-66 0-113-47t-47-113q0-66 47-113t113-47h80q33 0 56.5-23.5T600-600q0-33-23.5-56.5T520-680H313q-13 35-43.5 57.5T200-600q-50 0-85-35t-35-85q0-50 35-85t85-35q39 0 69.5 22.5T313-760h207q66 0 113 47t47 113q0 66-47 113t-113 47h-80q-33 0-56.5 23.5T360-360q0 33 23.5 56.5T440-280h207q13-35 43.5-57.5T760-360q50 0 85 35t35 85q0 50-35 85t-85 35ZM200-680q17 0 28.5-11.5T240-720q0-17-11.5-28.5T200-760q-17 0-28.5 11.5T160-720q0 17 11.5 28.5T200-680Z"/></svg>
                            <p className='text-xl font-semibold mb-3'>Instant conectivity</p>
                            <p className='text-balance text-sm mr-5'><strong>Thanks to NFC technology and QR codes</strong>, Astrobie Card allows those who scan it to access your information within seconds. Whether you’re a freelancer, an entrepreneur, or simply want to share your social media profiles,<strong>Astrobie Card makes it fast and secure.</strong> </p>
                        </div>
                        <div>
                            <svg className='h-24 w-24 fill-[#EE964B]' xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="m509-220 91-55 91 55-24-104 80-69-105-9-42-98-42 98-105 9 80 69-24 104ZM240-400v80h-80q-33 0-56.5-23.5T80-400v-400q0-33 23.5-56.5T160-880h400q33 0 56.5 23.5T640-800v80h-80v-80H160v400h80ZM400-80q-33 0-56.5-23.5T320-160v-400q0-33 23.5-56.5T400-640h400q33 0 56.5 23.5T880-560v400q0 33-23.5 56.5T800-80H400Zm0-80h400v-400H400v400Zm200-200Z"/></svg>
                        
                            <p className='text-xl font-semibold mb-3'>Elegant and <span className='text-[#94BB9B]'>Sustainable</span> Design</p>
                            <p className='text-balance text-sm mr-5'>The casing of Astrobie Card is meticulously crafted from recycled and biodegradable materials. Its sleek, aerodynamic design not only showcases your commitment to the environment but also embodies innovation. </p>
                        </div>
                        <div>
                        <svg className='h-24 w-24 fill-[#1D1D1D]' xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M160-120q-33 0-56.5-23.5T80-200v-440q0-33 23.5-56.5T160-720h160v-80q0-33 23.5-56.5T400-880h160q33 0 56.5 23.5T640-800v80h160q33 0 56.5 23.5T880-640v440q0 33-23.5 56.5T800-120H160Zm0-80h640v-440H160v440Zm240-520h160v-80H400v80ZM160-200v-440 440Z"/></svg>
                            <p className='text-xl font-semibold mb-3'>Your Digital Business Card</p>
                             <p className='text-balance text-sm mr-5'><strong>Unlike traditional paper cards,</strong> Astrobie Card doesn’t get lost or damaged. When scanned, your contacts will access a web page containing all your relevant information<strong>—from contact details to links to your social media profiles and website.</strong></p>
                        </div>
                    </div>
                </div>     
            </div>
        </section>
    )
}



