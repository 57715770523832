import React from 'react';

function AAHero() {
    return (
        <section className='bg-[#FDFFFC] text-[#1D1D1D] w-[100%] flex-col items-center justify-center'>
            <div className='mt-20 mb-24 md:mt-24 md:mb-24 px-6 mx-auto flex-column content-center static gap-8 max-w-[1144px]'>
                <div className='flex flex-row items-center'>
                    <img className='hidden md:flex md:h-[3rem] w-auto mr-2' alt='Astrobie Academy' src='/images/Logo_2_Astrobie_Sin_Fondo.png'></img>
                    <p className='text-4xl lg:text-6xl font-semibold'>Astrobie <span className='text-[#6650D5]'>A</span><span className='text-[#5AB489]'>ca</span><span className='text-[#FF6464]'>de</span><span className='text-[#FFA664]'>my</span></p>
                </div>
            </div>
        </section>
    )
}

export default AAHero