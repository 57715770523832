import React from "react";
import { Button, Card, CardBody, CardHeader, CardFooter, Image } from "@nextui-org/react";
import BasicModal from "../BasicModal";
import { Link } from "react-router-dom";

function Options() {
    return (
        <section className='bg-[#FDFFFC] px-6 m-auto text-[#1D1D1D] w-[100%] flex items-center justify-center'>
            <div className='max-w-[1144px] mt-20 mb-24 md:mt-24 md:mb-24'>


                <div className="text-4xl font-semibold">
                    <span className="text-[#1D1D1D]">
                        Options
                    </span>
                </div>
                <p className='text-xl text-[#1D1D1D] mb-8'>Profits</p>

                <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                    <Card className="bg-[#F4F6F3] lg:w-[400px] xl:w-[550px] min-w-[350px] h-[300px] mt-5 shadow-none">
                        <CardHeader>
                            <p className="text-xl md:text-2xl font-semibold">
                                For small businesses
                            </p>
                        </CardHeader>
                        <CardBody className="overflow-visible flex justify-end">
                            <Image
                                removeWrapper
                                alt="Small businesses"
                                className="z-0 h-[150xp] w-[150xp] object-cover"
                                src={""}
                            />
                        </CardBody>
                        <CardFooter className="bottom-0 z-10 h-16 justify-end flex">
                            {/* Call the  modal and pass the button to action it */}
                            <BasicModal
                                size="xl"
                                header={() => <h4>Rising by lifting others</h4>}
                                body={() => (
                                    <p>
                                        Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                                        Vero alias illo, quisquam cum porro et, repudiandae aliquam
                                        nobis inventore facere eligendi vel recusandae culpa eos
                                        illum nostrum autem voluptates laboriosam!
                                    </p>
                                )}
                                footer={() => (
                                    <Link to='/AvailableSoon'>
                                        <Button className="rounded-full bg-[#1D65C1] text-[#FDFFFC] mx-2">
                                            Register
                                        </Button>
                                    </Link>
                                )}
                                button={(handleClick) => (
                                    <Button
                                        isIconOnly
                                        className="bg-[#1D65C1] hover:bg-[#2b51cc] rounded-full text-[#FDFFFC] text-lg"
                                        onClick={handleClick}
                                    >
                                        &#43;
                                    </Button>
                                )}
                                classNames={{ all: "" }}
                            />
                        </CardFooter>
                    </Card>
                    <Card className="bg-[#F4F6F3] lg:w-[400px] xl:w-[550px] min-w-[350px] h-[300px] mt-5 shadow-none">
                        <CardHeader>
                            <p className="text-xl md:text-2xl font-semibold">
                                For companies
                            </p>
                        </CardHeader>
                        <CardBody className="overflow-visible flex justify-end">
                            <Image
                                removeWrapper
                                alt="Companies"
                                className="z-0 h-[150xp] w-[150xp] object-cover"
                                src={""}
                            />
                        </CardBody>
                        <CardFooter className="bottom-0 z-10 h-16 justify-end flex">
                            {/* Call the  modal and pass the button to action it */}
                            <BasicModal
                                size="xl"
                                header={() => <h4>Rising by lifting others</h4>}
                                body={() => (
                                    <p>
                                        Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                                        Vero alias illo, quisquam cum porro et, repudiandae aliquam
                                        nobis inventore facere eligendi vel recusandae culpa eos
                                        illum nostrum autem voluptates laboriosam!
                                    </p>
                                )}
                                footer={() => (
                                    <Link to='/enterprise'>
                                        <Button className="rounded-full bg-[#1D65C1] text-[#FDFFFC] mx-2">
                                            Register
                                        </Button>
                                    </Link>
                                )}
                                button={(handleClick) => (
                                    <Button
                                        isIconOnly
                                        className="bg-[#1D65C1] hover:bg-[#2b51cc] rounded-full text-[#FDFFFC] text-lg"
                                        onClick={handleClick}
                                    >
                                        &#43;
                                    </Button>
                                )}
                                classNames={{ all: "" }}
                            />
                        </CardFooter>
                    </Card>
                </div>
            </div>
        </section>
    )
}

export default Options