import React, { useContext, useState } from "react";
import { Input, Button, SelectItem, Select } from "@nextui-org/react";
import { StepperContext } from "../Context/StepperContext";

export default function ContactInfo({ handleClick, currentStep, steps }) {
  const { formData, updateFormData } = useContext(StepperContext);
  const [errors, setErrors] = useState({});
  const [value, setValue] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(formData);
    updateFormData({ ...formData, [name]: value });
  };

  const handleSelectionChange = (e) => {
    setValue(e.target.value);
    updateFormData({...formData, [e.target.name]: e.target.value})
  };

  const validateForm = () => {
    //put here the messages for the inputs with key like input id or name
    const validationRules = {
      ContactFirstName: "Business name is required",
      ContactLastName: "Business address  is required",
      ContactemailAddress: "Type is required",
      ContactemailAddressConfirm: "is Required",
      ContactphoneNumber: "Phone number is required",
      Contactgender: "contact gender is required",
      ContactRole: "contact role is required"
    };

    const newErrors = {};
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.ContactemailAddress))
      newErrors.ContactemailAddress = " invalid email format";

    if (!/^[0-9]{10}$/.test(formData.ContactphoneNumber))
      newErrors.ContactphoneNumber = " invalid number format";

    if (formData.ContactemailAddress !== formData.ContactemailAddressConfirm)
      newErrors.ContactemailAddressConfirm = "email address does not match";

    for (const [field, errorMessage] of Object.entries(validationRules)) {
      if (!formData[field]) {
        newErrors[field] = "This field is required";
      }
    }
    setErrors(newErrors);
    
    return Object.keys(newErrors).length === 0; // Retorna true si no hay errores
  };

  // Función para manejar el envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      handleClick("next");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <p className="my-5 font-semibold">Contact Information</p>
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-2 gap-5">
          {/**First name input*/}
          <Input
            type="text"
            label="First Name"
            onChange={handleChange}
            id="ContactFirstName"
            name="ContactFirstName"
            value={formData.ContactFirstName}
            isRequired
            isInvalid={errors.ContactFirstName ? true : false}
            errorMessage={errors.ContactFirstName}
          />
          {/**Last name input*/}
          <Input
            type="text"
            label="Last Name"
            onChange={handleChange}
            id="ContactLastName"
            name="ContactLastName"
            value={formData.ContactLastName}
            isRequired
            isInvalid={errors.ContactLastName ? true : false}
            errorMessage={errors.ContactLastName}
          />
        </div>

        <div className="grid grid-cols-2 gap-5">
          {/**Contactgender */}
          <Select
            size="lg"
            name="Contactgender"
            id="Contactgender"
            label="Gender"
            labelPlacement="outside-left"
            isRequired
            placeholder="Select a country"
            selectedKeys={[value]}
            className="w-full items-center"
            onChange={handleSelectionChange}
          >
            <SelectItem key={"Male"}>Male</SelectItem>
            <SelectItem key={"Female"}>Female</SelectItem>
            <SelectItem key={"Non-binary"}>Non-binary</SelectItem>
            <SelectItem key={"Other"}>Other</SelectItem>
            <SelectItem key={"I prefer not to say it"}>I prefer not to say it</SelectItem>
          </Select>

          {/**university position/role */}
          <Input
            type="text"
            label="Role at the university"
            onChange={handleChange}
            id="ContactRole"
            name="ContactRole"
            value={formData.ContactRole}
            isRequired
            isInvalid={errors.ContactRole ? true : false}
            errorMessage={errors.ContactRole}
          />
        </div>

        <div className="grid grid-cols-2 gap-5">
          {/**Email address */}
          <Input
            type="email"
            label="Email Address"
            onChange={handleChange}
            id="ContactemailAddress"
            name="ContactemailAddress"
            value={formData.ContactemailAddress}
            isRequired
            isInvalid={errors.ContactemailAddress ? true : false}
            errorMessage={errors.ContactemailAddress}
          />
          

          {/*confirm email */}
          <Input
            type="email"
            label="Confirm Email Address"
            onChange={handleChange}
            id="ContactemailAddressConfirm"
            name="ContactemailAddressConfirm"
            value={formData.ContactemailAddressConfirm}
            isRequired
            isInvalid={errors.ContactemailAddressConfirm ? true : false}
            errorMessage={errors.ContactemailAddressConfirm}
          />
        </div>

        <div className="grid grid-cols-2 gap-5">
          {/**Phone number */}
          <Input
            type="number"
            label="Phone Number"
            onChange={handleChange}
            id="ContactphoneNumber"
            name="ContactphoneNumber"
            value={formData.ContactphoneNumber}
            isRequired
            isInvalid={errors.ContactphoneNumber ? true : false}
            errorMessage={errors.ContactphoneNumber}
          />
        </div>


        <p>we will never share or sell your information with others.</p>
        
      </div>

      <div className="container flex justify-around mt-4 mb-8">
        <Button
          onClick={() => handleClick()}
          className={`bg-[#FDFFFC] text-[#1D1D1D] px-4 py-2 rounded-full 
font-semibold cursor-pointer border-1 border-[#1D1D1D] hover:text-[#FDFFFC] hover:bg-[#1D1D1D] transition duration-200 ease-in-out ${
            currentStep === 1
              ? "opacity-50 cursor-not-allowed"
              : "cursor-pointer"
          }`}
        >
          Back
        </Button>

        <Button
          type="submit"
          className="bg-[#1D65C1] px-4 py-2 rounded-full 
font-semibold  border-1 border-[#1D65C1] text-[#FDFFFC] cursor-pointer"
        >
          {currentStep === steps.length ? "Confirm" : "Next"}
        </Button>
      </div>
    </form>
  );
}
