import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardFooter, CardHeader, Image } from "@nextui-org/react";

// All the cards will have a default text and default image, with this we don't have problem if we forget write a param
export default function OutsourcingCard(props) {
    if (!props.visible) {
        return null
    }
    else {
        return (
          <Card className="max-w-[456px] h-[300px] shadow-md">
            <CardHeader className="absolute z-10 top-1 flex-col items-start">
                <p className="text-xs font-normal text-[#1D1D1D] uppercase">{props.subtitle}</p>
                <h4 className="text-[#1D1D1D] font-semibold text-2xl">{props.title}</h4>
            </CardHeader>
            <Image
                removeWrapper
                alt="app background"
                className="z-0 w-full h-full object-cover"
                src={props.image}
            />
            <CardFooter className="absolute bottom-0 z-10 h-16 flex items-end justify-end">
                <Link to={`/${props.route}`}>
                    <Button radius="full" size="sm" className='bg-[#1D65C1] hover:bg-[#2b51cc] text-[#FDFFFC]'>More</Button>
                </Link>
            </CardFooter>
        </Card>
        )
    }
}