import React, { useContext, useEffect, useState } from "react";
import { Input, Button, Select, SelectItem } from "@nextui-org/react";
import { StepperContext } from "../Context/StepperContext";
import axios from "axios";

export default function SchoolInformation({ handleClick, currentStep, steps }) {
  const { formData, updateFormData } = useContext(StepperContext);

  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorLoad, setErrorLoad] = useState(null);
  const [errors, setErrors] = useState({});
  const [value, setValue] = useState("");

  useEffect(() => {
    // get Country list
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        setCountries(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setErrorLoad(err);
        setLoading(false);
      });
  }, []);

  const sortedCountries = [...countries].sort((a, b) => 
    a.name.common.localeCompare(b.name.common)
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    //console.log(formData);
    updateFormData({ ...formData, [name]: value });
  };

  const handleSelectionChange = (e) => {
    setValue(e.target.value);
    updateFormData({...formData, [e.target.name]: e.target.value})
  };

  // Función para validar los campos del formulario
  const validateForm = () => {
    //put here the messages for the inputs with key like input id or name
    const validationRules = {
      schoolName: "Name is required",
      schoolPhysicalAddress: "Physical address is required",
      schoolCountry: "Select a country",
      schoolPhoneNumber: "Phone number is required",
      schoolWebsiteURL: "Website is required"
    };

    const newErrors = {};

    if (!/^[0-9]{10}$/.test(formData.schoolPhoneNumber))
      newErrors.schoolPhoneNumber = "number format is incorrect";

    if (!/^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/.test(formData.schoolWebsiteURL))
      newErrors.schoolPhoneNumber = "URL format is incorrect";

    for (const [field, errorMessage] of Object.entries(validationRules)) {
      if (!formData[field]) {
        newErrors[field] = "This field is required";
      }
    }

    setErrors(newErrors);
    console.log(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Función para manejar el envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(validateForm())
    if (validateForm()) {
      handleClick("next");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <p className="my-5 font-semibold">School Information</p>
      <div className="flex flex-col gap-4">
        <div className="grid md:grid-cols-2 gap-5">
          {/**school name */}
          <Input
            type="Text"
            label="School name"
            onChange={handleChange}
            id="schoolName"
            name="schoolName"
            value={formData.schoolName}
            isRequired
            isInvalid={errors.schoolName ? true : false}
            errorMessage={errors.schoolName}
          />
          {/**school address */}
          <Input
            type="Text"
            label="Physical address"
            onChange={handleChange}
            id="schoolPhysicalAddress"
            name="schoolPhysicalAddress"
            value={formData.schoolPhysicalAddress}
            isRequired
            isInvalid={errors.schoolPhysicalAddress ? true : false}
            errorMessage={errors.schoolPhysicalAddress}
          />
        </div>

        <div className="grid md:grid-cols-2 gap-5">
          {/**Country */}
          <Select
          size="lg"
            name="schoolCountry"
            id="schoolCountry"
            label="Country"
            labelPlacement="outside-left"
            isRequired
            placeholder="Select a country"
            selectedKeys={[value]}
            className="w-full items-center"
            onChange={handleSelectionChange}
          >
            {sortedCountries.map((country) => (
              <SelectItem key={country.name.common} value={country.name.common}>
                {country.name.common}
              </SelectItem>
            ))}
          </Select>

          {/**State */}
          <Input
            type="Number"
            label="Phone number"
            onChange={handleChange}
            id="schoolPhoneNumber"
            name="schoolPhoneNumber"
            value={formData.schoolPhoneNumber}
            isRequired
            isInvalid={errors.schoolPhoneNumber ? true : false}
            errorMessage={errors.schoolPhoneNumber}
          />
        </div>

        <div className="grid md:grid-cols-2 gap-5">
          {/**website */}
          <Input
            type="Text"
            isRequired
            label="Website URL"
            onChange={handleChange}
            name="schoolWebsiteURL"
            id="schoolWebsiteURL"
            value={formData.schoolWebsiteURL}
            isInvalid={errors.schoolWebsiteURL ? true : false}
            errorMessage={errors.schoolWebsiteURL}
          />
        </div>

        <p>we will never share or sell your information with others.</p>

        <div className="container flex justify-around mt-4 mb-8">
          <Button
            onClick={() => handleClick()}
            className={`bg-[#FDFFFC] text-[#1D1D1D] px-4 py-2 rounded-full 
font-semibold cursor-pointer border-1 border-[#1D1D1D] hover:text-[#FDFFFC] hover:bg-[#1D1D1D] transition duration-200 ease-in-out ${
              currentStep === 1
                ? "opacity-50 cursor-not-allowed"
                : "cursor-pointer"
            }`}
          >
            Back
          </Button>

          <Button
            type="submit"
            className="bg-[#1D65C1] px-4 py-2 rounded-full 
font-semibold  border-1 border-[#1D65C1] text-[#FDFFFC] cursor-pointer"
          >
            {currentStep === steps.length ? "Confirm" : "Next"}
          </Button>
        </div>
      </div>
    </form>
  );
}
