import React from 'react'

function AddOn() {
    return (
        <section name='solution' className='bg-[#FDFFFC] px-6 m-auto text-[#1D1D1D] w-[100%] flex items-center justify-center'>
            <div className='max-w-[1144px] mt-20 mb-24 md:mt-24 md:mb-24  items-center justify-center'>
                <div className="text-4xl font-semibold">
                    <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#1D1D1D] to-[#1D1D1D]">
                        Add-ons
                    </span>
                </div>
                <p className="text-xl mb-8">Complement your package</p>

            </div>
        </section>
    )
}

export default AddOn