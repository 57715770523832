import React, { useContext, useState } from "react";
import { Input, Button } from "@nextui-org/react";
import { StepperContext } from "../Context/StepperContext";

export default function EmpoweringEducation({
  handleClick,
  currentStep,
  steps,
}) {
  return (
    <div>
      <p className="my-5 font-semibold">Empowering Education Together</p>
      <p className="text-balance">
        Becoming an Astrobie Academy partner means joining a collaborative
        effort to transform education and make a lasting impact on students
        worldwide. As a partner, your institution can engage students in
        hands-on experiences through internships and volunteering, enhance our
        platform by donating educational resources, and support our programs
        through financial contributions. Additionally, you can host educational
        events like hackathons and workshops, collaborate on research and
        development projects, promote Astrobie Academy through your networks,
        and provide advisory and mentorship.
      </p>
      <div className="container flex justify-around mt-4 mb-8">
        <Button
          onClick={() => handleClick()}
          className={`bg-[#FDFFFC] text-[#1D1D1D] px-4 py-2 rounded-full 
font-semibold cursor-pointer border-1 border-[#1D1D1D] hover:text-[#FDFFFC] hover:bg-[#1D1D1D] transition duration-200 ease-in-out ${
            currentStep === 1
              ? "opacity-50 cursor-not-allowed"
              : "cursor-pointer"
          }`}
        >
          Back
        </Button>

        <Button
          onClick={() => handleClick("next")}
          className="bg-[#1D65C1] px-4 py-2 rounded-full 
font-semibold  border-1 border-[#1D65C1] text-[#FDFFFC] cursor-pointer"
        >
          {currentStep === steps.length ? "Confirm" : "Next"}
        </Button>
      </div>
    </div>
  );
}
