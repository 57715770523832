import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Accordion,
  AccordionItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalContent,
  ModalHeader,
  useDisclosure,
  Input,
  Textarea,
  Checkbox,
} from "@nextui-org/react";
import { useForm } from "react-hook-form";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase/config";
import Toast from './Alert';

function Faq() {
  const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return true;
    }
    return window.innerWidth <= 767;
  };

  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const [isSelected, setIsSelected] = React.useState(false);

  // Initialize react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // Handle the form submit
  const onSubmit = (data) => {
    //console.log(data);
    const questionRef = collection(db, "faq");
    addDoc(questionRef, data)
      .then((doc)=>{
        onOpenChange(false);
        reset();
        Toast.fire({
          icon: "success",
          title: "Good question! Someone will review and answer your question"});
      })
      .catch((reason)=>{
        Toast.fire({
          icon: "error",
          title: "We have had some problems, please try again."});
      })
      
  };

  return (
    <section
      name="faq"
      className="p-0 m-0 py-36 bg-[#FDFFFC]  flex-col items-center justify-center w-[100%]"
    >
      <div className=" px-6 text-[#1D1D1D] mx-auto flex-column content-center static gap-8 max-w-[1144px]">
        <div className="pl-2">
          <p className="text-4xl font-semibold text-left">
            Frequently Asked Questions
          </p>
          <p className="text-xl text-[#1D1D1D] mb-8">
            Quick answers to questions you may have
          </p>
        </div>

        <div className="items-center">
          <Accordion>
            <AccordionItem
              classNames={{
                title: "font-medium",
                content: "text-[#1D1D1D] font-normal text-sm",
              }}
              key="1"
              aria-label="Accordion 1"
              title="Why is Astrobie not letting me purchase certain products?"
            >
              {
                "You may not be able to purchase a product when the product you are trying to purchase is not available in your area or it is backordered. Feel free to susbcribe to our newsletter to make sure you get a notification when our product is back in stock or become available in your area."
              }
            </AccordionItem>
            <AccordionItem
              classNames={{
                title: "font-medium",
                content: "text-[#1D1D1D] font-normal text-sm",
              }}
              key="2"
              aria-label="Accordion 2"
              title="Is Astrobie safe?"
            >
              {
                "Astrobie is totally safe and legit. Our website have an SSL certificate which allows to process HTTPS requests, this is a safe way to encrypt all of your data within our website and services. Plus, we use secured and trusted transaction software implementation that will safely manage your purchases."
              }
            </AccordionItem>
            <AccordionItem
              classNames={{
                title: "font-medium",
                content: "text-[#1D1D1D] font-normal text-sm",
              }}
              key="3"
              aria-label="Accordion 3"
              title="How can I volunteer for Astrobie’s non-profit service?"
            >
              {
                "Some projects are made out of community work and donations, which means our non-profit Astrobie Academy service operates from volunteering and colective work. If you are interested on becaming a partner, contact volunteer@astrobie.com"
              }
            </AccordionItem>
            <AccordionItem
              classNames={{
                title: "font-medium",
                content: "text-[#1D1D1D] font-normal text-sm",
              }}
              key="4"
              aria-label="Accordion 4"
              title="Can I find Astrobie Store outside astrobie.com?"
            >
              {
                "Yes. Our products are also offered through other ecommerce platforms such as Amazon."
              }
            </AccordionItem>
          </Accordion>
        </div>

        <div className="grid grid-2 md:grid-cols-4 bg-[#F4F6F3] px-8 py-6 rounded-2xl mt-24">
          <div className="md:col-span-3">
            <p className="font-semibold text-lg mb-2">Still have questions?</p>
            <p className="text-sm">
              <span className="font-semibold">
                Couldn't find what you were looking for?{" "}
              </span>
              Redact your personalized question and we'll get to you shortly.
            </p>
          </div>
          <div className="flex items-center justify-end mt-4 md:mt-0 md:justify-end">
            <Button
              onPress={onOpen}
              className="bg-[#1D65C1] rounded-full text-white"
              size="s"
            >
              Redact
            </Button>
          </div>
        </div>

        <Modal
          size="xl"
          isDismissable={!isMobile()}
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          onClose={()=>{reset();}}
          className="bg-[#FDFFFC]"
        >
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <ModalContent>
              {(onClose) => (
                <>
                  <ModalHeader className="flex flex-col gap-1 text-xl">
                    Redact your question
                  </ModalHeader>
                  <ModalBody>
                    <p className="mt-4 font-semibold">Personal details:</p>
                    <Input
                      variant="flat"
                      isRequired
                      type="text"
                      label="Your Full Name"
                      {...register("fullName", {
                        required: "Full Name is required",
                      })}
                      isInvalid={errors.fullName ? true : false}
                      color={errors.fullName ? "#D84363" : ""}
                      errorMessage={errors.fullName?.message}
                    />
                    <Input
                      variant="flat"
                      isRequired
                      type="email"
                      label="Email Address"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value:  /^[a-z A-Z 0-9 . _ -]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                          message: "Invalid email address",
                        },
                      })}
                      isInvalid={errors.email ? true : false}
                      color={errors.email ? "#D84363" : ""}
                      errorMessage={errors.email?.message}
                    />
                    <p className="mt-4 font-semibold">Your question:</p>
                    <Input
                      variant="flat"
                      isRequired
                      type="text"
                      label="Question"
                      {...register("question", {
                        required: "Question is required",
                      })}
                      isInvalid={errors.question ? true : false}
                      color={errors.question ? "#D84363" : ""}
                      errorMessage={errors.question?.message}
                    />
                    <Textarea
                      isRequired
                      label="Description"
                      variant="flat"
                      placeholder="Enter your description"
                      disableAnimation
                      disableAutosize
                      description="Enter a concise description of your question"
                      classNames={{
                        base: "max-w-full",
                        input: "resize-y min-h-[40px] max-w-[50vh]",
                      }}
                      {...register("description", {
                        required: "Description is required",
                      })}
                      isInvalid={errors.description ? true : false}
                      color={errors.description ? "#D84363" : ""}
                      errorMessage={errors.description?.message}
                    />
                    <div className="flex flex-row">
                      <Checkbox
                        onValueChange={setIsSelected}
                        {...register("acceptTerms", {
                          required: "You must accept the terms",
                        })}
                        isInvalid={errors.acceptTerms ? true : false}
                        color={errors.acceptTerms ? "#D84363" : ""}
                        size="sm"
                      >
                        I accept&nbsp;
                      </Checkbox>
                      <Link
                        to="terms-conditions"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="text-[#1D64C1] text-sm cursor-pointer">
                          terms and conditions
                        </span>
                      </Link>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      className="bg-transparent rounded-full border-1 border-[#1d1d1d59] text-[#1D1D1D] lg:hover:bg-[#1D1D1D] lg:hover:text-[#FDFFFC]"
                      onPress={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      isDisabled={!isSelected}
                      className="bg-[#1D64C1] rounded-full text-[#FDFFFC]"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </ModalFooter>
                </>
              )}
            </ModalContent>
          </form>
        </Modal>
      </div>
    </section>
  );
}
export default Faq;
