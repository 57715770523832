import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Image,
  Button,
  divider,
  CardBody,
  image,
} from "@nextui-org/react";

import clsx from "clsx";
import { twMerge } from "tailwind-merge";
import BasicModal from "../BasicModal";

export default function ConnectivityCard(props) {
  const className = twMerge(
    "max-w-[456px] min-w-[300px] cursor-pointer mb-14 mt-5 mx-4",
    props.className
  );
  const classNames = {
    header: twMerge("block", props.classNames.header),
    title: twMerge(
      "text-xs font-normal text-[#EE964B] uppercase",
      props.classNames.title
    ),
    content: twMerge(
      "text-[#1D1D1D] font-semibold text-2xl",
      props.classNames.content
    ),
    image: twMerge("z-0 h-[150xp] w-[150xp] object-cover"),
    footer: twMerge("block bottom-0 z-10 h-16", props.classNames.footer),
    button: twMerge(
      "bg-[#1D65C1] hover:bg-[#2b51cc] text-[#FDFFFC] text-lg",
      props.classNames.button
    ),
    body: twMerge("overflow-visible flex justify-end", props.classNames.button),
  };

  const buttonProps = {
    isIconOnly: true,
    "aria-label": "Info",
    radius: "full",
    size: "sm",
  };

  return (
    <Card key={props.id} className={className} {...props}>
      <CardHeader className={classNames.header}>
        <p className={classNames.title}>{props.title}</p>
        <h4 className={classNames.content}>{props.content}</h4>
      </CardHeader>
      <CardBody className={classNames.body}>
        <Image
          removeWrapper
          alt={props.title}
          className={classNames.image}
          src={props.image}
        />
      </CardBody>
      <CardFooter className={classNames.footer}>
        <BasicModal
          header={() => 
            <Image
              alt={props.title}
              src={props.imageForModal}
              className="-z-20 rounded-none mb-5"
            />
          }
          body={() => 
            <div>
                <h4 className={classNames.content}>{props.content}</h4>
                <p>{props.description}</p>
            </div>}
          button={(handleClick) => (
            <Button
              {...buttonProps}
              className={classNames.button}
              onClick={handleClick}
            >
              &#43;
            </Button>
          )}
          classNames={{header:"p-0"}}
        />
        
      </CardFooter>
    </Card>
  );
}
