import { ReactLenis } from 'lenis/react'
import { motion, useMotionTemplate, useScroll, useTransform } from "framer-motion";
import { FiMapPin } from "react-icons/fi";
import { useRef } from "react";
export const AAHeroSection = () => {
    return (
        <section className='bg-[#FDFFFC] text-[#1D1D1D] w-[100%] flex-col items-center justify-center'>
            <div className='mt-20 mb-24 md:mt-24 md:mb-24 px-6 mx-auto flex-column content-center static gap-8'>
                <div className="bg-[#FDFFFC]">
                    <ReactLenis
                        root
                        options={{
                            lerp: 0.05,
                        }}
                    >
                        <Hero />
                        <Country />
                    </ReactLenis>
                </div>
            </div>
        </section>
    );
};
const SECTION_HEIGHT = 1500;
const Hero = () => {
    return (
        <div
            style={{ height: `calc(${SECTION_HEIGHT}px + 100vh)` }}
            className="relative w-full"
        >
            <CenterImage />
            <ParallaxImages />
            <div className="absolute bottom-0 left-0 right-0 h-96 bg-gradient-to-b from-[#FDFFFC] to-[#FDFFFC]" />
        </div>
    );
};
const CenterImage = () => {
    const { scrollY } = useScroll();
    const clip1 = useTransform(scrollY, [0, 1500], [0, 0]);
    const clip2 = useTransform(scrollY, [0, 1500], [100, 100]);
    const clipPath = useMotionTemplate`polygon(${clip1}% ${clip1}%, ${clip2}% ${clip1}%, ${clip2}% ${clip2}%, ${clip1}% ${clip2}%)`;
    const backgroundSize = useTransform(
        scrollY,
        [0, SECTION_HEIGHT + 500],
        ["100%", "150%"]
    );
    const opacity = useTransform(
        scrollY,
        [SECTION_HEIGHT, SECTION_HEIGHT + 500],
        [1, 0]
    );
    return (
        <motion.div
            className="sticky top-0 h-screen w-full "
            style={{
                clipPath,
                backgroundSize,
                opacity,
                backgroundImage:
                    "url('https://images.pexels.com/photos/3912979/pexels-photo-3912979.jpeg')",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
            }}
        />
    );
};
const ParallaxImages = () => {
    return (
        <div className="mx-auto max-w-5xl px-4 pt-[200px]">
            <ParallaxImg
                src="https://images.pexels.com/photos/2506594/pexels-photo-2506594.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="And example of a space launch"
                start={-200}
                end={-100}
                className=" w-1/3"
            />
            <ParallaxImg
                src="https://images.pexels.com/photos/6256253/pexels-photo-6256253.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="An example of a space launch"
                start={-100}
                end={-500}
                className="mx-auto w-1/3"
            />
            <ParallaxImg
                src="https://images.pexels.com/photos/5496464/pexels-photo-5496464.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="Orbiting satellite"
                start={-400}
                end={-1000}
                className="ml-auto w-1/3"
            />
        </div>
    );
};
const ParallaxImg = ({ className, alt, src, start, end }) => {
    const ref = useRef(null);
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: [`${start}px end`, `end ${end * -1}px`],
    });
    const opacity = useTransform(scrollYProgress, [0.75, 1], [1, 0]);
    const scale = useTransform(scrollYProgress, [0.75, 1], [1, 0.85]);
    const y = useTransform(scrollYProgress, [0, 1], [start, end]);
    const transform = useMotionTemplate`translateY(${y}px) scale(${scale})`;
    return (
        <motion.img
            src={src}
            alt={alt}
            className={className}
            ref={ref}
            style={{ transform, opacity }}
        />
    );
};
// COUNTRIES SECTION
const Country = () => {
    return (
        <section
            id="launch-schedule"
            className="mx-auto max-w-5xl px-4 py-48 text-[#1D1D1D]"
        >
            <motion.h1
                viewport={{once: true}}
                initial={{ y: 48, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ ease: "easeInOut", duration: 0.75 }}
                className="mb-20 text-4xl font-semibold"
            >
                Global Approach
            </motion.h1>
            <CountryItem country="Mexico" continent="North America" city="Moroleon"/>
            <CountryItem country="United States" continent="North America" city="Chicago"/>
            <CountryItem country="Kenya" continent="Africa" city="Nairobi"/>
            <CountryItem country="India" continent="Asia" city="Indore"/>
            <CountryItem country="Colombia" continent="South America" city="Bogota"/>
        </section>
    );
};
const CountryItem = ({ country, continent, city }) => {
    return (
        <motion.div
            viewport={{once: true}}
            initial={{ y: 48, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ ease: "easeInOut", duration: 0.75 }}
            className="mb-6 flex items-center justify-between border-b border-zinc-800 px-3 pb-6 text-[#1D1D1D]"
        >
            <div>
                <p className="text-2xl text-[#1D1D1D] uppercase font-medium">{country}</p>
                <p className='text-lg text-[#1d1d1d98]'>{continent}</p>
            </div>
            <div className="flex items-center gap-1.5 text-end text-sm uppercase text-zinc-500">
                <p>{city}</p>
                <FiMapPin/>
            </div>
        </motion.div>
    );
};