import React, { useContext, useState, useEffect } from "react";
import { Input, Button, Textarea, Select, SelectItem } from "@nextui-org/react";
import { useForm } from "react-hook-form";
import { StepperContext } from "../Context/StepperContext";
import axios from "axios";

export default function Curriculum({ handleClick, currentStep, steps }) {
  const { formData, updateFormData } = useContext(StepperContext);
  const [value, setValue] = useState("");
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorLoad, setErrorLoad] = useState(null);

  useEffect(() => {
    // get Country list
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        setCountries(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setErrorLoad(err);
        setLoading(false);
      });
  }, []);

  const sortedCountries = [...countries].sort((a, b) =>
    a.name.common.localeCompare(b.name.common)
  );

  const handleSelectionCountryChange = (e) => {
    setCountry(e.target.value);
    updateFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(formData);
    updateFormData({ ...formData, [name]: value });
  };

  const handleSelectionChange = (e) => {
    setValue(e.target.value);
    updateFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Función para validar los campos del formulario
  const validateForm = () => {
    //put here the messages for the inputs with key like input id or name
    const validationRules = {
      school: "school name is required",
      deegre: "deegre  is required",
      field: "field is required",
      experience: "experience is required",
      abilities: "abilities is required",
      anythingElse: "anythingElse code is required",
    };

    const newErrors = {};

    for (const [field, errorMessage] of Object.entries(validationRules)) {
      if (!formData[field]) {
        newErrors[field] = "This field is required";
      }
    }

    setErrors(newErrors);
    console.log(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Función para manejar el envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      handleClick("next");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <p className="my-5 font-semibold">Curriculum</p>
      <div className="flex flex-col gap-4">
        <div className="grid md:grid-cols-2 gap-5">
          {/**school */}
          <Input
            type="Text"
            label="School You Attended "
            onChange={handleChange}
            id="school"
            name="school"
            value={formData.school}
            isRequired
            isInvalid={errors.school ? true : false}
            errorMessage={errors.school}
          />
          {/**deegre */}
          <Input
            type="Text"
            label="Degree Awarded"
            onChange={handleChange}
            id="deegre"
            name="deegre"
            value={formData.deegre}
            isRequired
            isInvalid={errors.deegre ? true : false}
            errorMessage={errors.deegre}
          />
        </div>

        <div className="grid md:grid-cols-2 gap-5">
          {/**Field */}
          <Select
            size="lg"
            name="field"
            id="field"
            label="Field"
            labelPlacement="outside-left"
            isRequired
            placeholder="Select a field"
            selectedKeys={[value]}
            className="w-full items-center"
            onChange={handleSelectionChange}
          >
            <SelectItem key={"Technology Team"}>Technology Team</SelectItem>
            <SelectItem key={"Marketing Team"}>Marketing Team</SelectItem>
            <SelectItem key={"Designers Team"}>Designers Team</SelectItem>
            <SelectItem key={"Academy Ambassador"}>
              Academy Ambassador
            </SelectItem>
          </Select>
          {/**experience */}
          <Textarea
            classNames={{
              base: "",
              input: "resize-y min-h-[40px] w-full",
            }}
            disableAnimation
            disableAutosize
            type="Text"
            label="experience"
            onChange={handleChange}
            id="experience"
            name="experience"
            value={formData.experience}
            isRequired
            isInvalid={errors.experience ? true : false}
            errorMessage={errors.experience}
          />
        </div>

        <div className="grid md:grid-cols-2 gap-5">
          {/**abilities  */}
          <Input
            type="Text"
            label="abilities"
            isRequired
            onChange={handleChange}
            id="abilities"
            name="abilities"
            value={formData.abilities}
            isInvalid={errors.abilities ? true : false}
            errorMessage={errors.abilities}
          />
          {/**anythingElse Code */}
          <Textarea
            classNames={{
              base: "",
              input: "resize-y min-h-[30px] w-full",
            }}
            disableAnimation
            disableAutosizef
            type="Text"
            label="Anything else you may want to share with us"
            onChange={handleChange}
            id="anythingElse"
            name="anythingElse"
            value={formData.anythingElse}
            isInvalid={errors.anythingElse ? true : false}
            errorMessage={errors.anythingElse}
          />
        </div>

        <div className="grid md:grid-cols-2 gap-5">
          {/**website */}
          <Input
            type="Text"
            label="City"
            onChange={handleChange}
            name="city"
            id="city"
            value={formData.city}
            isRequired
            isInvalid={errors.city ? true : false}
            errorMessage={errors.city}
          />

          <Select
            size="lg"
            name="country"
            id="country"
            label="Country"
            labelPlacement="outside-left"
            isRequired
            placeholder="Select a country"
            selectedKeys={[country]}
            className="w-full items-center"
            onChange={handleSelectionCountryChange}
          >
            {sortedCountries.map((country) => (
              <SelectItem key={country.name.common} value={country.name.common}>
                {country.name.common}
              </SelectItem>
            ))}
          </Select>
        </div>

        <div className="grid md:grid-cols-2 gap-5">
          {/**website */}
          <Input
            type="Text"
            label="Website URL (if applicable)"
            onChange={handleChange}
            name="website"
            id="website"
            value={formData.website}
          />
        </div>

        <p>we will never share or sell your information with others.</p>

        <div className="container flex justify-around mt-4 mb-8">
          <Button
            onClick={() => handleClick()}
            className={`bg-[#FDFFFC] text-[#1D1D1D] px-4 py-2 rounded-full 
font-semibold cursor-pointer border-1 border-[#1D1D1D] hover:text-[#FDFFFC] hover:bg-[#1D1D1D] transition duration-200 ease-in-out ${
              currentStep === 1
                ? "opacity-50 cursor-not-allowed"
                : "cursor-pointer"
            }`}
          >
            Back
          </Button>

          <Button
            type="submit"
            className="bg-[#1D65C1] px-4 py-2 rounded-full 
font-semibold  border-1 border-[#1D65C1] text-[#FDFFFC] cursor-pointer"
          >
            {currentStep === steps.length ? "Confirm" : "Next"}
          </Button>
        </div>
      </div>
    </form>
  );
}
