import React, {useState} from 'react'
import {Button, Input} from "@nextui-org/react";

import { collection, addDoc } from "firebase/firestore";
import { db } from '../firebase/config';
import Toast from './Alert';
function Newsletter() {

    const [isLoading, setIsLoading] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const emailValid = () =>{
        const regEx=  /^\S+@\S+$/i
        if (regEx.test(inputValue)) {
          saveData()
        } else{
            Toast.fire({
                icon: "error",
                title: "Please introduce a valid email"
              });
        }   
    };

    const saveData = () => {
        setIsLoading(true)
        const email = {
            email: inputValue,
        }
        //console.log(email);
        const newsletterRef = collection(db, "newsletter");

        addDoc(newsletterRef, email)
            .then((doc) => {
                // SENT AN ALERT TO LET KNOW THE USER THAT HE'S SUBSCRIBED TO OUR NEWSLETTER SWEETALERT
                setIsLoading(false)
                setInputValue("")
                Toast.fire({
                    icon: "success",
                    title: "Congratulations, you are now part of Astrobie"
                });
            })
    }


    return (
        <section className='bg-[#FDFFFC] text-[#1D1D1D] px-6 flex items-center justify-center m-auto'>
            <div className='mt-20 mb-20 md:mt-24 md:mb-24 max-w-[1144px] mx-auto'>
                <div className='flex flex-row items-center'>
                    <p className='text-4xl font-semibold'>Be the first one</p>
                </div>
                <div>
                    <p className='text-xl'>Don't miss anything. Subscribe to our newsletter.
                    </p>
                </div>
                <div className='flex items-center justify-center mt-5'>
                    <Input
                        isDisabled={isLoading}
                        type="email"
                        label="Email"
                        variant="bordered"
                        className="max-w-xs"
                        radius="full"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                    />
                </div>
                <div className="w-full flex items- justify-center mt-5">
                    <Button
                        className="bg-[#1D65C1] rounded-full text-white"
                        size="s"
                        onClick={emailValid}
                        isLoading={isLoading} // Disable the button if buttonDisabled is true
                    >
                        Subscribe
                    </Button>

                </div>
            </div>
        </section>
    )
}

export default Newsletter