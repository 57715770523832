import React from 'react'
import { Card, Badge } from "@nextui-org/react";

function Support() {
    return (
        <section className='bg-[#FDFFFC] px-6 m-auto text-[#1D1D1D] w-[100%] flex items-center justify-center'>
            <div className='max-w-[1144px] mt-20 mb-24 md:mt-24 md:mb-24 items-center justify-center'>

                <div className="text-4xl font-semibold">
                    <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#1D1D1D] to-[#1D1D1D]">
                        Support Available
                    </span>
                </div>
                {/* Talk about the challenges that small businesses encounter */}
                <p className="text-xl mb-8">You don't have to be alone during the process.</p>

                <div className='grid md:grid grid-cols-1 md:grid-cols-3 my-7 gap-6 items-center justify-items-center md:justify-items-stretch'>

                    <div className="relative">
                        <Badge content="Start-Up" className=' bg-[#CD8987] absolute top-[30px] left-[-10px] translate-x-0 -translate-y-1/2 w-32 border-none'>
                            {/* Badge content here */}
                        </Badge>
                        <Card className="bg-[#F4F6F3]  max-w-64 rounded-xl shadow-lg">
                            {/* Price Section */}
                            <div className="flex items-end justify-center pt-10 text-center">
                                <p className='font-semibold text-4xl'>Free</p>
                            </div>

                            {/* Features List */}
                            <div className="p-6">
                            <ul className='ml-2 list-disc mt-2 text-xs text-[#1D1D1D]'>
                                <li className='my-2 marker:text-[#48a52b]'>IT specialist advice/training</li>
                                <li className='my-2 marker:text-[#db5d5d] text-[#1d1d1d42]'>Manage your Astrobie account</li>
                                <li className='my-2 marker:text-[#db5d5d] text-[#1d1d1d42]'>IT Helpdesk</li>
                                <li className='my-2 marker:text-[#db5d5d] text-[#1d1d1d42]'>Get a brand new UI design each year</li>
                                <li className='my-2 marker:text-[#db5d5d] text-[#1d1d1d42]'>24/7 support</li>
                                <li className='my-2 marker:text-[#db5d5d] text-[#1d1d1d42]'>Get marketing mockups twice a year</li>
                                <li className='my-2 marker:text-[#db5d5d] text-[#1d1d1d42]'>Cibersecurity measurements</li>
                                {/* <li className='my-2 marker:text-[#db5d5d] text-[#1d1d1d42]'>Antivirus protection for your devices (unlimited devices)</li> */}
                            </ul>
                            </div>
                        </Card>
                    </div>

                    <div className="relative">
                        <Badge content="Basic" className=' bg-[#1D65C1] text-[#FDFFFC] absolute top-[30px] left-[-10px] translate-x-0 -translate-y-1/2 w-32 border-none'>
                            {/* Badge content here */}
                        </Badge>
                        <Card
                            className="bg-[#F4F6F3] max-w-64 rounded-xl shadow-lg"
                        >
                            {/* Price Section */}
                            <div className=" flex items-end justify-center pt-10 text-center ">
                                <p className='font-semibold text-5xl'>$59.99</p>
                                <p className='font-medium text-medium '>/mo</p>
                            </div>

                            {/* Features List */}
                            <div className="p-6">
                                <ul className='ml-2 list-disc mt-2 text-xs text-[#1D1D1D]'>
                                    <li className='my-2 marker:text-[#48a52b]'>IT specialist advice/training</li>
                                    <li className='my-2 marker:text-[#48a52b]'>Manage your Astrobie account</li>
                                    <li className='my-2 marker:text-[#48a52b]'>IT Helpdesk</li>
                                    <li className='my-2 marker:text-[#48a52b]'>Get a brand new UI design each year</li>
                                    <li className='my-2 marker:text-[#db5d5d] text-[#1d1d1d42]'>24/7 support</li>
                                    <li className='my-2 marker:text-[#db5d5d] text-[#1d1d1d42]'>Get marketing mockups twice a year</li>
                                    <li className='my-2 marker:text-[#db5d5d] text-[#1d1d1d42]'>Cibersecurity measurements</li>
                                    {/* <li className='my-2 marker:text-[#48a52b]'>Antivirus protection for your devices (up to 5 devices)</li> */}
                                </ul>
                            </div>
                        </Card>
                    </div>

                    <div className="relative">
                        <Badge content="Pro" className=' bg-[#EEA25C] absolute top-[30px] left-[-10px] translate-x-0 -translate-y-1/2 w-32 border-none'>
                            {/* Badge content here */}
                        </Badge>
                        <Card className="bg-[#F4F6F3]  max-w-64 rounded-xl shadow-lg">
                            {/* Price Section */}
                            <div className="flex items-end justify-center pt-10 text-center">
                                <p className='font-semibold text-4xl'>$99.99</p>
                                <p className='font-medium text-medium'>/mo</p>
                            </div>

                            {/* Features List */}
                            <div className="p-6">
                                <ul className='ml-2 list-disc mt-2 text-xs text-[#1D1D1D]'>
                                    <li className='my-2 marker:text-[#48a52b]'>IT specialist advice/training</li>
                                    <li className='my-2 marker:text-[#48a52b]'>Manage your Astrobie account</li>
                                    <li className='my-2 marker:text-[#48a52b]'>IT Helpdesk</li>
                                    <li className='my-2 marker:text-[#48a52b]'>Get a brand new UI design each year</li>
                                    <li className='my-2 marker:text-[#48a52b]'>24/7 support</li>
                                    <li className='my-2 marker:text-[#48a52b]'>Get marketing mockups twice a year</li>
                                    <li className='my-2 marker:text-[#48a52b]'>Cibersecurity measurements</li>
                                    {/* <li className='my-2 marker:text-[#48a52b]'>Antivirus protection for your devices (unlimited devices)</li> */}
                                </ul>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Support