import React from "react";
import { Check, Cancel } from "../resources/Icons";
function Feature() {
  return (
      <section className="bg-[#FDFFFC] px-6 m-auto text-[#1D1D1D] w-[100%] flex items-center justify-center">
        <div className="max-w-[1144px] mt-20 mb-24 md:mt-24 md:mb-24 w-full">
          <div className="text-4xl font-semibold mb-8">
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#1D1D1D] to-[#1D1D1D] px-5">
            Your Choice, Your Way
          </span>
          </div>
          <div className="grid grid-cols-3 rounded-lg overflow-hidden mx-5">
            {/* Headers */}
            <div className=" font-medium md:text-2xl flex justify-center border h-[20vh] items-center">
              Features
            </div>
            <div className="font-semibold md:text-2xl flex justify-center border border-t-5 h-[20vh] items-center">
              Others
            </div>
            <div className="font-semibold md:text-2xl flex justify-center items-center border border-t-5 border-t-[#1D65C1] bg-[#1d64c11a]  h-[20vh] rounded-tr-lg">
              Astrobie
            </div>
            {/* Row 1 */}
            <div className="flex justify-center items-center text-center border border-gray-300 p-2 h-[10vh]">
              Security packages
            </div>
            <div className="flex justify-center items-center border border-gray-300 p-2">
              <Cancel />
            </div>
            <div className="bg-[#1d64c11a] flex justify-center items-center border border-gray-300 p-2">
              <Check />
            </div>
            {/* Row 2 */}
            <div className="flex justify-center text-center items-center border border-gray-300 p-2 h-[10vh]">
              Support and Assistance
            </div>
            <div className="flex justify-center items-center border border-gray-300 p-2">
              <Cancel />
            </div>
            <div className="bg-[#1d64c11a] flex justify-center items-center border border-gray-300 p-2">
              <Check />
            </div>
            {/* Row 3 */}
            <div className="flex justify-center text-center items-center border border-gray-300 p-2 h-[10vh]">
              Free domain registration
            </div>
            <div className="flex justify-center items-center border border-gray-300 p-2">
              <Cancel />
            </div>
            <div className="bg-[#1d64c11a] flex justify-center items-center border border-gray-300 p-2">
              <Check />
            </div>
            {/* Row  4*/}
            <div className="flex justify-center text-center items-center border border-gray-300 p-2 h-[10vh]">
              Partnership Discounts
            </div>
            <div className="flex justify-center border items-center border-gray-300 p-2">
              <Cancel />
            </div>
            <div className="bg-[#1d64c11a] flex justify-center items-center border border-gray-300 p-2">
              <Check />
            </div>
            {/* Row  5*/}
            <div className="flex justify-center text-center items-center border border-gray-300 p-2 h-[10vh]">
              Affordable prices
            </div>
            <div className="flex justify-center items-center border border-gray-300 p-2">
              <Cancel />
            </div>
            <div className="bg-[#1d64c11a] flex justify-center items-center border border-gray-300 p-2">
              <Check />
            </div>
            {/* Row 6 */}
            <div className="flex justify-center text-center items-center border border-gray-300 p-2 h-[10vh]">
              No mandatory fees
            </div>
            <div className="flex justify-center items-center border border-gray-300 p-2">
              <Cancel />
            </div>
            <div className="bg-[#1d64c11a] flex justify-center items-center border border-gray-300 p-2">
              <Check />
            </div>
          </div>
        </div>
      </section>
  );
}
export default Feature;