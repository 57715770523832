import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";

//import `NextUIProvider` component
import {NextUIProvider} from "@nextui-org/react";

// Global Components
import Navbar from '../src/components/Navbar.jsx';
import Footer from '../src/components/Footer.jsx';
import ScrollToTopButton from './components/ScrollToTopButton.js';
import ScrollToTop from './components/ScrollToTop.js';

// Landing Page
import Hero from './components/Hero.jsx';
import Outsourcing from './components/outsourcing/Outsourcing.jsx';
import Products from './components/Products/Products.jsx';
import AstrobieAcademy from './components/AstrobieAcademy/AstrobieAcademy.jsx';
import Solution from './components/Solution.jsx';
import News from './components/News/News.jsx';
import Community from './components/Community.jsx';
import AboutUs from './components/AboutUs.jsx';
import Faq from './components/FAQ.jsx';
import CTA from './components/CTA.jsx';
import Newsletter from './components/Newsletter.jsx';

// Contact Us
import ContactUs from './components/ContactUs.jsx';

// Others
import NotAvailable from "./components/NotAvailable";
import Header from "./components/astrobie-card/Header";
import TermsConditions from './components/TermsConditions.jsx';
import CustomerSupport from './components/CustomerSupport.jsx';


// Astrobie Cards
import HeroSection from "./components/astrobie-card/HeroSection";
import Design from "./components/astrobie-card/Design";
import Connectivity from './components/astrobie-card/Connectivity.jsx';
import ScrollParallax from "./components/astrobie-card/ScrollParallax";
import TechnicalSpecifications from './components/astrobie-card/TechnicalSpecifications.jsx';
import Information from './components/astrobie-card/Information.jsx';
import AAContactUs from './components/AstrobieAcademy/AAContactUs.jsx';

// About Us Route
import AUHero from './components/AboutUs/AUHero.jsx';

//Astrobie Academy
import AAHero from './components/AstrobieAcademy/AAHero.jsx';
import ComingSoon from './components/AstrobieAcademy/ComingSoon.jsx';
import Testimonials from './components/AstrobieAcademy/Testimonials.jsx';
import AAStory from './components/AstrobieAcademy/AAStory.jsx';
import Details from './components/AstrobieAcademy/Details.jsx';
import MeetUsAt from './components/AstrobieAcademy/MeetUsAt.jsx';
import Learning from './components/AstrobieAcademy/Learning.jsx';
import Volunteer from './components/AstrobieAcademy/Volunteer/Volunteer.jsx';

// Web Assistance (Outsourcing)
import WAHeroSection from './components/outsourcing-website/WAHeroSection.jsx';
import WAMain from './components/outsourcing-website/WAMain.jsx';
import Feature from './components/outsourcing-website/Feature.jsx';
import WebsiteType from './components/outsourcing-website/WebsiteType.jsx';
import Hosting from './components/outsourcing-website/Hosting.jsx';
import Support from './components/outsourcing-website/Support.jsx';
import AddOn from './components/outsourcing-website/AddOn.jsx';
import { AAHeroSection } from './components/AstrobieAcademy/AAHeroSection.jsx';

//Enterprise
import Enterprise from './components/Enterprise/Enterprise.jsx';
import Options from './components/outsourcing-website/Options.jsx';
import PrivacyPolicy from './components/PrivacyPolicy.jsx';
import University from './components/AstrobieAcademy/University/University.jsx';




function App() {

    return (
        <NextUIProvider>

            <BrowserRouter>

                <ScrollToTop/>
                <Navbar/>
                <ScrollToTopButton/>

                    <Routes>
                        <Route path='/' element={[
                                <Hero/>,
                                <Outsourcing/>,
                                <Products/>,
                                <AstrobieAcademy/>,
                                <Solution/>,
                                <News/>,
                                <AboutUs/>,
                                <Faq/>,
                                <Newsletter/>,
                                <ScrollToTopButton/>
                        ]}/>

                        <Route path="privacy-policy" element={<PrivacyPolicy/>}/>

                        <Route path='AvailableSoon' element={<NotAvailable/>}/>
                        <Route path='outsourcing' element={
                            <Outsourcing classNames={{section: "h-[80vh]"}}/>
                        }/>
                        <Route path='products' element={
                            <Products classNames={{section: "h-[80vh]"}}/>
                        }/>
                        <Route path='about' element={<AboutUs/>}/>
                        <Route path='faq' element={<Faq/>}/>

                        <Route path='astrobie-card' element={[
                            <HeroSection title={"Astrobie Card"} subtitle={"Simple. Secure. Dynamic"} />,
                            <Header name={"Astrobie Card"} 
                            links={
                                [
                                    {
                                        name:"Information", 
                                        url:"information"
                                    },
                                    {
                                        name:"Connectivity", 
                                        url:"connectivity"
                                    },
                                    {
                                        name:"Design", 
                                        url:"design"
                                    },
                                    {
                                        name:"Materials", 
                                        url:"materials"
                                    },
                                    {
                                        name:"Specifications", 
                                        url:"specifications"
                                    }
                                ]
                            }
                            />,
                            <Information />,
                            <Design />,
                            <Connectivity />,
                            <ScrollParallax/>,
                            <TechnicalSpecifications/>
                        ]} />

                        <Route path='website-assistance' element={[
                            <WAHeroSection/>,
                            <Header name={"Web Assistance"}/>,
                            <WAMain/>,
                            <Feature/>,
                            <WebsiteType/>,
                            <Hosting/>,
                            <Support/>,
                            <AddOn/>,
                            <Options/>
                        ]}
                        />

                        <Route path='academy' element={[
                            <AAHero/>,
                            <AAHeroSection/>,
                            <Learning/>,
                            <MeetUsAt/>,
                            <AAStory/>,
                            <Testimonials/>,
                            <Details/>,
                            <ComingSoon/>,
                            // <AAContactUs/>,
                            
                            
                        ]}/>

                        <Route path='contact-us' element={[
                            <ContactUs/>
                        ]}/>

                        <Route path='terms-conditions' element={[
                            <TermsConditions/>
                        ]}/>

                        <Route path='about-us' element={[
                            <AUHero/>
                        ]}/>
                       <Route path='enterprise' element={[
                            <Enterprise/>
                       ]}/>
                       <Route path='volunteer' element={[
                            <Volunteer/>
                       ]}/>
                       <Route path='University' element={[
                            <University/>
                       ]}/>

                      <Route path='Support' element={[<CustomerSupport/>]}/>
                    </Routes>

                <Footer/>
            </BrowserRouter>

        </NextUIProvider>
);
}

export default App;
