import React, { useContext, useState } from "react";
import { Input, Button } from "@nextui-org/react";
import { StepperContext } from "../Context/StepperContext";

export default function ContactInfo({ handleClick, currentStep, steps }) {
  const { formData, updateFormData } = useContext(StepperContext);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(formData);
    updateFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {

       //put here the messages for the inputs with key like input id or name
       const validationRules = {
        firstName: 'Business name is required',
        lastName: 'Business address  is required',
        contactEmail: 'Type is required',
        contactEmailconfirm:'is Required',
        role:'State is required',
        contactPhone: 'Phone number is required',
        gender:'Zip code is required'
  
      };

    const newErrors = {};
    if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(formData.contactEmail))
      newErrors.contactEmail = " invalid email format";

     if (!(/^[0-9]{10}$/).test(formData.contactPhone))
      newErrors.contactPhone = " invalid number format";

    if(formData.contactEmail!==formData.contactEmailconfirm)
      newErrors.contactEmailconfirm = "email address does not match";

    for (const [field, errorMessage] of Object.entries(validationRules)) {
      if (!formData[field]) {
        newErrors[field] = "This field is required";
      }

    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0; // Retorna true si no hay errores
  };

  // Función para manejar el envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      handleClick("next");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <p className="my-5">Contact Information</p>
      <div className="flex flex-col gap-4">
        <div  className="grid grid-cols-2 gap-5">
            {/**First name input*/}
          <Input
            type="text"
            label="First Name"
            onChange={handleChange}
            id="firstName"
            name="firstName"
            value={formData.firstName}
            isRequired
            isInvalid={errors.firstName ? true : false}
            errorMessage={errors.firstName}
          />
          {/**Last name input*/}
          <Input
            type="text"
            label="Last Name"
            onChange={handleChange}
            id="lastName"
            name="lastName"
            value={formData.lastName}
            isRequired
            isInvalid={errors.lastName ? true : false}
            errorMessage={errors.lastName}
          />

        </div>

        <div className="grid grid-cols-2 gap-5">
          {/**Email address */}
          <Input
            type="email"
            label="Email Address"
            onChange={handleChange}
            id="contactEmail"
            name="contactEmail"
            value={formData.contactEmail}
            isRequired
            isInvalid={errors.contactEmail ? true : false}
            errorMessage={errors.contactEmail}
          />
          {/*confirm email */}
          <Input
            type="email"
            label="Confirm Email Address"
            onChange={handleChange}
            id="contactEmailconfirm"
            name="contactEmailconfirm"
            value={formData.contactEmailconfirm}
            isRequired
            isInvalid={errors.contactEmailconfirm ? true : false}
            errorMessage={errors.contactEmailconfirm}
          />
        </div>

          {/**Companys position/role */}
        <div className="grid grid-cols-2 gap-5">
          <Input
            type="text"
            label="Company's Position/Role"
            onChange={handleChange}
            id="role"
            name="role"
            value={formData.role}
            isRequired
            isInvalid={errors.role ? true : false}
            errorMessage={errors.role}
            
          />

            {/**Gender */}
          <Input
            type="text"
            label="Gender"
            onChange={handleChange}
            id="gender"
            name="gender"
            value={formData.gender}
            isRequired
            isInvalid={errors.gender ? true : false}
            errorMessage={errors.gender}
      
          />
        </div>

        <div className="grid grid-cols-2 col-span-1">
           {/**Phone number */}
           <Input
          type="text"
          label="Phone Number"
          onChange={handleChange}
          id="contactPhone"
          name="contactPhone"
          value={formData.contactPhone}
          isRequired
          isInvalid={errors.contactPhone? true : false}
          errorMessage={errors.contactPhone}
        />
        </div>
       
      </div>

      <div className="container flex justify-around mt-4 mb-8">
        <Button
          onClick={() => handleClick()}
          className={`bg-[#FDFFFC] text-[#1D1D1D] px-4 py-2 rounded-full 
font-semibold cursor-pointer border-1 border-[#1D1D1D] hover:text-[#FDFFFC] hover:bg-[#1D1D1D] transition duration-200 ease-in-out ${currentStep === 1
              ? "opacity-50 cursor-not-allowed"
              : "cursor-pointer"
            }`}
        >
          Back
        </Button>

        <Button
          type="submit"

          className="bg-[#1D65C1] px-4 py-2 rounded-full 
font-semibold  border-1 border-[#1D65C1] text-[#FDFFFC] cursor-pointer"
        >
          {currentStep === steps.length ? "Confirm" : "Next"}
        </Button>
      </div>
    </form>
  );
}
