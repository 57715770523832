import React, {useEffect, useState} from "react";
import ProductCard from "./ProductCard.jsx";
// import {products} from "./data";
import {collection, getDocs} from "firebase/firestore";
import {db} from "../../firebase/config";

import {Card, Skeleton} from "@nextui-org/react";
import {twMerge} from "tailwind-merge";
import clsx from "clsx";

function Products(props) {

  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(true); // INITIAL STATE

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const getProducts = async () => {
      try {
        // VERIFY LOCAL DATA
        if (products.length === 0) {
          // console.log("Fetching from server...");
          const querySnapshot = await getDocs(collection(db, "products"), {
            signal,
          });
          const docs = [];
          querySnapshot.forEach((doc) => {
            docs.push({ ...doc.data(), id: doc.id });
          });
          // console.log("Data fetched from server:", docs);
          setProducts(docs);
        } else {
          // console.log("Using local data:", products);
        }
        setLoading(false); // END LOAD
      } catch (error) {
        // console.error("ERROR fetching data:", error);
      }
    };

    getProducts();

    return () => {
      // REQUEST CANCELED IF COMPONENT UNMOUNTED
      // console.log("Aborting request...");
      controller.abort();
    };
  }, [products]); // DEPENDENCY TO AVOID UNNECESSARY REQUEST


  const classNames = {
    section: twMerge(clsx("bg-[#FDFFFC] px-6 m-auto text-[#1D1D1D] flex items-center justify-center"), props.classNames?.section)
  }

  if (loading) {
    return (
      <section className={classNames.section}>
        <div className="mt-20 mb-24 md:mt-24 md:mb-24 items-center justify-center max-w-[1144px] mx-auto">
          <div className="text-4xl font-semibold">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#1D1D1D] to-[#1D1D1D]">
              Products
            </span>
          </div>
          <p className="text-xl">We Create. You Enjoy.</p>
          <div className="md:grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-12 my-7">
            <Card className="lg:w-[456px] my-7 shadow-md max-lg:my-4 h-[200px] mx-auto rounded-lg">
              <div className="grid grid-cols-2">
                <div>
                  <Skeleton className="rounded-lg top-1 my-4 mx-4">
                    <div className="h-4 rounded-lg bg-default-300"></div>
                  </Skeleton>
                  <Skeleton className="rounded-lg top-1 mb-4 mx-4">
                    <div className="h-24 rounded-lg bg-default-300"></div>
                  </Skeleton>
                  <Skeleton className="rounded-lg mb-4 mx-4 w-1/5">
                    <div className="h-5 rounded-lg bg-default-200"></div>
                  </Skeleton>
                </div>
                <div>
                  <Skeleton className="rounded-r-lg rounded-l-none">  
                    <div className="h-[200px] bg-default-300"></div>
                  </Skeleton>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className={classNames.section}>
      <div className="mt-20 mb-24 md:mt-24 md:mb-24 items-center justify-center max-w-[1144px] mx-auto">
        <div className="text-4xl font-semibold">
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#1D1D1D] to-[#1D1D1D]">
            Products
          </span>
        </div>
        <p className="text-xl">We Create. You Enjoy.</p>
        <div className="md:grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-12 my-7">
          {products.map((product) => (
            <ProductCard
                isWhite={product.isWhite}
                key={product.id}
                name={product.name}
                description={product.description}
                image={product.image}
                url={product.url}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Products;
