import React from 'react'
import {Link} from "react-router-dom";
import { SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {twMerge} from "tailwind-merge";
import clsx from "clsx";
function HeroElements(props) {
    const classNames = {
        exploreButton: twMerge(clsx("text-sm hover:text-[#1D1D1D] bg-transparent border-solid border-1 border-[#FDFFFC] hover:bg-[#FDFFFC] duration-200 rounded-full px-3 py-1"), props?.explore),
        button: twMerge(clsx("text-sm bg-[#1D65C1] border-solid border-1 border-[#1D65C1] hover:bg-[#264E83] hover:border-[#264E83] duration-200 rounded-full px-6 py-1"), props?.buttton)
    }
    return (
        <SwiperSlide className='px-6 cursor-grab'>
            <div className='my-14 grid grid-cols-1 md:grid-cols-2 gap-2'>
                <div className='my-0 md:my-7'>
                    <p className='text-xl uppercase'>{props.heading}</p>
                    <div className="text-6xl font-semibold">
                        <span className="text-[#FDFFFC]">
                            {props.title}
                        </span>
                    </div>
                    <p className='text-sm mt-4'>{props.subtitle}</p>
                    <div className='flex flex-row my-7'>
                        <Link to={props.route} className='mr-4'><button className={classNames.button}>Explore</button></Link>
                    </div>
                </div>
                <div className='flex justify-center lg:justify-end'>
                    <img
                        className='w-auto max-h-[300px] '
                        removeWrapper
                        alt={props.title + " Product Image"}
                        src={props.image}
                    />
                </div>
            </div>
        </SwiperSlide>
    )
}
export default HeroElements
