import React from "react";
import {Button, Input} from "@nextui-org/react";
import { Link } from "react-router-dom";

export default function Complete() {
    return(
        
            <div className="flex flex-col gap-4 justify-center items-center">
                <p className="text-3xl font-bold ">We are all set!</p>
                <div className="grid justify-items-stretch">
                    <p className="text-medium mb-3">An Astrobie LLC technician will contact you to further explore your project. The process is as follows:</p>
                    <ol className="space-y-3">
                        <li>
                            <label className="font-semibold">1. Form Submission: </label>
                            You submit your form, providing our team with all necessary details about yourself, your company, and your project. (This is the form you have just submitted.)
                        </li>
                        <li>
                            <label className="font-semibold">2. Email Confirmation: </label>Expect an email confirmation within a couple of hours. Our team will review your request and strive to accommodate your needs.
                        </li>
                        <li>
                            <label className="font-semibold">3. Agent Assignment: </label>An agent will be assigned to your case. This person will serve as your liaison with Astrobie LLC. Together, you will explore your project in depth and reach a preliminary agreement. This agent will then present your project to our dedicated team.
                        </li>
                        <li>
                            <label className="font-semibold">4. Project Review: </label>After reviewing your project, Astrobie LLC will contact you directly to mutually agree on the final legal terms and conditions. Together, we will establish the workflow and collaborate closely to ensure you are satisfied with your website.
                        </li>
                        <li>
                            <label className="font-semibold">5. Project Completion: </label>Upon completion of our work, your assigned Astrobie technician will manage the website for a short period (as established in the contract). After this period, you can continue to receive support by subscribing to one of our support plans available at <Link to={"/website-assistance"} className="text-[#1D65C1]">astrobie.com/WebAssistance</Link>.
                        </li>
                    </ol>
                    <Button className="justify-self-end rounded-full bg-[#1D65C1] text-[#FDFFFC] mt-5"><Link to={"/"}>Return to main page</Link></Button>
                </div>
                
                
            </div>
    
        
    )
}
