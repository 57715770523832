import React, { useState } from "react"
import Stepper from "./Stepper";
//import StepperControl from "./StepperController";
import { StepperContext } from "./Context/StepperContext";
import ContactInfo from "./Steps/ContactInfo";
import Complete from "./Steps/Complete";


//firebase 
import { addDoc, collection } from "firebase/firestore";
import { db } from '../../../firebase/config';
import Journey from "./Steps/Journey";
import Curriculum from "./Steps/Curriculum";

// Form component to make a enterprise registartion

export default function VolunteerForm() {


    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({
        school:'',
        deegre: '',
        field:'',
        experience:'',
        abilities:'',
        anythingElse:'',
        website:'',
        contactEmailconfirm:'',
        contactEmail: '',
        gender:"",
        firstName:'',
        lastName:'',
        country:'',
        city:'',
        contactPhone:'',
        message:""
        
    });

    const steps = [
        "Introduce",
        "Curriculum ",
        "Contact ",
        "Complete"
    ];

    const updateFormData = (newData) => {
        setFormData(prevData => ({ ...prevData, ...newData }));
    };


    const saveData = async () => {
        try {
            const dataRef = collection(db, "volunteer");
            await addDoc(dataRef, {
                ...formData,
                createdAt: new Date()
            });
            console.log("Datos guardados correctamente.");
            setFormData({
                school:'',
                deegre: '',
                field:'',
                experience:'',
                abilities:'',
                anythingElse:'',
                website:'',
                contactEmailconfirm:'',
                contactEmail: '',
                gender:"",
                firstName:'',
                lastName:'',
                country:'',
                city:'',
                contactPhone:'',
                message:""


                // Reinicia otros campos según sea necesario
            });
        } catch (error) {
            console.error("failed to save the data", error)
            console.log("No jala.");
        }

    }
    const displayStep = (step) => {

        switch (step) {
            case 1:
                return <Journey
                handleClick={handleClick}
                currentStep={currentStep}
                steps={steps}/>
            case 2:
                return <Curriculum 
                 handleClick={handleClick}
                currentStep={currentStep}
                steps={steps}/>
               
            case 3:
                return <ContactInfo  
                 handleClick={handleClick}
                currentStep={currentStep}
                steps={steps}/>
            case 4:
                return <Complete />
            default:
                return null;
        }
    }

    
    const handleClick = (direction) => {
        let newStep = currentStep;

        direction === "next" ? newStep++ : newStep--;
        //check if steps are within bound
        newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);

        if (newStep === steps.length) { 
            console.log(steps.length)
            saveData();
        }

    }





    return (
        <section className='bg-[#FDFFFC] text-[#1D1D1D] px-6 flex items-center justify-center m-auto'>
            <div className='mt-20 mb-20 md:mt-24 md:mb-24 max-w-[1144px] mx-80 w-full'>
                <div className="text-4xl font-semibold">
                    <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#1D1D1D] to-[#1D1D1D]">
                        Volunteer form
                    </span>
                </div>
                <p className="text-xl mb-8">We Create. You Enjoy.</p>
                <div className='mt-20 mb-20 md:mt-24 md:mb-24 max-w-[1144px] w-[90vw] mx-auto shadow-xl rounded-2xl py-2'>


                    {/*Stepper*/}
                    <div className="container horizontal mt-5 px-6">
                        <Stepper
                            steps={steps}
                            currentStep={currentStep}
                        />
                    </div>
                    <div className="my-10 sm:p-10 px-6 pb-6">
                        {/*Display components*/}
                        <StepperContext.Provider
                            value={{
                                formData,
                                updateFormData
                            }}>
                            {displayStep(currentStep)}
                        </StepperContext.Provider>
                    </div>
                    {/*Stepper buttons*/}
                    <div>
                        {/*currentStep !== steps.length &&
                        <StepperControl
                            handleClick={handleClick}
                            currentStep={currentStep}
                            steps={steps}
                        />*/}
                    </div>
                            
                </div>
            </div>


        </section>
    )
}