import React from "react";
import { Card, CardHeader, CardBody, Image} from "@nextui-org/react";

const Learning = () => {
    const subjects = [
        {
            title: "Science",
            color: "text-[#5AB489]",
            image: "/images/AAScience.png",
        },
        {
            title: "Technology",
            color: "text-[#6650D5]",
            image: "/images/AATechnology.png",
        },
        {
            title: "Engineering",
            color: "text-[#FFA664]",
            image: "/images/AAEngineering.png",
        },
        {
            title: "Mathematics",
            color: "text-[#FF6464]",
            image: "/images/AAMathematics.png",
        },
    ];

    return (
        <section className="px-6 m-auto text-[#1D1D1D] w-[100%] flex items-center justify-center">
            <div className="flex w-full flex-col max-w-[1144px] mt-20 mb-24 md:mt-24 md:mb-24">
                <div className="text-4xl md:text-4xl font-semibold">
                    <span className="text-[#1D1D1D]">
                        Your <span className="text-[#1D64C1]">learning</span> path
                    </span>
                </div>
                <p className="text-xl mb-8">Growing the STEM community worldwide</p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-12">
                    {subjects.map((subject, index) => (
                        <Card
                            key={index}
                            className={`w-full h-[300px] mt-5 shadow-lg`}>
                            <CardHeader className='flex-col items-start'>
                                <p className={`text-3xl md:text-4xl font-semibold ${subject.color}`}>{subject.title}</p>
                            </CardHeader>
                            <CardBody className="m-0 p-0">
                                <Image
                                    removeWrapper
                                    alt="learning path"
                                    className="z-10 w-full h-full object-cover"
                                    src={subject.image}
                                />
                            </CardBody>
                        </Card>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Learning;
